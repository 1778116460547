import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-club-header',
  templateUrl: './club-header.component.html',
  styleUrls: ['./club-header.component.scss'],
})
export class ClubHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as momentTz from 'moment-timezone';
import {EnvironmentService} from "../environment/environment.service";
import {Storage} from "@capacitor/storage";

@Injectable({
    providedIn: 'root',
})
export class LocaleService {

    locale = "fr-FR";
    env: any;

    constructor(
        private translateService: TranslateService,
        private environmentService: EnvironmentService
    ) {
        this.env = environmentService.getEnvFile();
    }

    getLocale() {
        if (this.locale) {
            if (this.locale.includes('-')) {
                this.locale = this.locale.split('-')[0];
            }
            return this.locale;
        }
        return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
    }

    setAppLocale() {
        Storage.get({key: 'locale'}).then(resp => {
            if (resp.value) {
                this.locale = resp.value;
            } else {
                this.locale = this.getDeviceLocale();
            }
        });
        // this.setLanguage(this.locale);

        return this.locale;
    }

    getDeviceLocale() {
        if (window.Intl && typeof window.Intl === 'object') {
            const browserLocale = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
            this.locale = (browserLocale.includes('fr') || browserLocale.includes('en')) ? browserLocale : this.locale;
            if (this.locale.includes('-')) {
                this.locale = browserLocale.split('-')[0];
            }
        } else {
            this.locale = this.env.defaultLang;
        }
        return this.locale;
    }

    setLanguage(lang = 'fr-FR') {
        if (lang.includes('-')) {
            lang = lang.split('-')[0];
        }
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);
        if (this.env.useMb) {
            this.translateService.use(lang + '_' + this.env.marqueBlanche.pathName);
        }
        momentTz.locale(lang);

        Storage.set({key: 'locale', value: lang}).then();
    }

    getCalendarOptions() {
        return  this.locale.includes('fr') ? { weekStart: 1, weekdays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'] } : null;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EPayComponent } from './e-pay.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [EPayComponent],
  exports: [EPayComponent]
})
export class EPayModule { }

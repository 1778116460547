import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SignComponent } from './sign.component';
import {SelectLocaleComponent} from "../../../components/select-locale/select-locale.component";

@NgModule({
    declarations: [SignComponent, SelectLocaleComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        TranslateModule
    ],
    exports: []
})
export class SignModule {}

export const envPasserelle = {
  "bo-metz": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Metz',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Metz',
      pathName: 'bo-metz',
      clubIds: ['ed0ed0af-2c3c-4f9e-873c-fe5d7889f11d'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  "bo-bordeaux": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Bordeaux',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Bordeaux',
      pathName: 'bo-bordeaux',
      clubIds: ['13fe5057-06cf-4fa7-b79b-eaec31c063a0'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  "bo-evry": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Evry',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Evry',
      pathName: 'bo-evry',
      clubIds: ['581dd52e-87ae-4fd2-9677-5f8401158917'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  "bo-cergy": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Cergy',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Cergy',
      pathName: 'bo-cergy',
      clubIds: ['622553cd-c16e-4fab-818e-b8da3ec3a4cb'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  "bo-paris": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Paris',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Paris',
      pathName: 'bo-paris',
      clubIds: ['9a81a6e8-3f49-4f2c-9e8a-2ced29b5a838'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  "bo-lyon": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Lyon',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Lyon',
      pathName: 'bo-lyon',
      clubIds: ['bbd9a831-0b68-4f0f-8ae4-34109cdc40ae'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  "bo-reims": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Reims',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Reims',
      pathName: 'bo-reims',
      clubIds: ['cc172f04-c5b7-4fa2-a2c5-0123aaf0796a'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  "bo-tours": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Tours',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Tours',
      pathName: 'bo-tours',
      clubIds: ['e2c24dc1-82f9-4f8b-9769-a7d9e4c43d5f'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },


  agora:{
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Agora Koumassi',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'ci',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Agora Koumassi',
      pathName: 'agora',
      clubIds: ['acd58c60-c596-4f10-868b-aa70b3fe4a16'],
      countclub: 1,
      whiteLabelId: '7d4751ff-ffdd-4f9b-939f-fec4255d3560',
      useMatch: true
    }
  },
  hangarpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Hangar Padel',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Hangar Padel',
      pathName: 'hangarpadel',
      clubIds: ['8fb4f6bd-843d-4f02-ac79-b6e04497995c'],
      countclub: 1,
      whiteLabelId: '1012ae4a-7a49-4f49-b58b-12ba733dbc1d',
      useMatch: true
    }
  },
  voilesbeachclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Les Voiles Beach Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Les Voiles Beach Club',
      pathName: 'voilesbeachclub',
      clubIds: ['79385e7c-8599-4fde-8fee-68a1ab6c5145'],
      countclub: 1,
      whiteLabelId: 'e75e10ed-aef3-4f39-ae71-160efc558f15',
      useMatch: false
    }
  },
  mamsoccerpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Mam\'Soccer 5 Padel',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Mam\'Soccer 5 Padel',
      pathName: 'mamsoccerpadel',
      clubIds: ['31ab31dc-28a8-4fb0-8a4e-7947c5270016'],
      countclub: 1,
      whiteLabelId: 'd643d725-82f2-4fcb-af06-2f77175f3989',
      useMatch: true
    }
  },
  youpiparc: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Youpi Parc',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Youpi Parc',
      pathName: 'youpiparc',
      clubIds: ['28b48d7d-c397-4f87-a6ff-ff290e47be0d'],
      countclub: 1,
      whiteLabelId: null,
      useMatch: false
    }
  },
  xlpark: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'XL Park',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'XL Park',
      pathName: 'xlpark',
      clubIds: ['bb98a6d9-4307-4f22-a794-6c2f012f90b6'],
      countclub: 1,
      whiteLabelId: '89fb5a36-b118-4fcb-a13a-05d1b6dd2340',
      useMatch: false
    }
  },
  "bo-vitrolles": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-blockout.doinsport.club',
    pathFiles: 'https://api-blockout.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Block\' Out Vitrolles',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Block\' Out Vitrolles',
      pathName: 'bo-vitrolles',
      clubIds: ['e6c28938-3e1b-4fb1-b82c-1db91026e16a'],
      countclub: 1,
      whiteLabelId: '06ab7cd0-5154-4fc2-b0b2-4bc9c8227788',
      useMatch: false
    }
  },
  grenadine: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Grenadine & Crayonnade',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Grenadine & Crayonnade',
      pathName: 'grenadine',
      clubIds: ['473740fd-d5ce-4fd2-a389-639b0d5aa31d'],
      countclub: 1,
      whiteLabelId: '584fde5a-eee4-4f63-979f-9195d2271f37',
      useMatch: false
    }
  },
  jumparena: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Jump Arena - Bordeaux',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Jump Arena - Bordeaux',
      pathName: 'jumparena',
      clubIds: ['b22b91d5-d9e5-4fde-856a-3af2c4f1f1ee'],
      countclub: 1,
      whiteLabelId: '7f3217ba-3992-4f6b-9e51-cda81fc3e852',
      useMatch: false
    }
  },
  hossegortennis: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Hossegor Tennis Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Hossegor Tennis Club',
      pathName: 'hossegortennis',
      clubIds: ['d093bc9d-8f40-4f02-b309-78b54f617719'],
      countclub: 1,
      whiteLabelId: '6ec13052-8944-4f83-b1cd-edbd2acf974f',
      useMatch: true
    }
  },
  blocspot: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Bloc Spot',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Bloc Spot',
      pathName: 'blocspot',
      clubIds: ['d8987cac-6210-4f6d-86da-1f4ef9c777fc'],
      countclub: 1,
      whiteLabelId: 'd1a45301-0cf2-4fa3-bc43-39eec7e45fdd',
      useMatch: true
    }
  },
  provencetir: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Club Provence Tir',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Club Provence Tir',
      pathName: 'provencetir',
      clubIds: ['631ce2d1-434a-4f54-bbab-462585d91e03', 'e3bb0712-cde9-4fc3-b940-0bdec88688f0'],
      countclub: 2,
      whiteLabelId: 'a55489fa-d8fb-4f7a-89e5-412abe5a9aff',
      useMatch: false
    }
  },
  lapyrite: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'La Pyrite',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'La Pyrite',
      pathName: 'lapyrite',
      clubIds: ['96fd4802-7cac-4f45-bf13-87b78fd77883'],
      countclub: 1,
      whiteLabelId: 'f9797e22-e692-4f0d-8243-902a8c0ebb8a',
      useMatch: true
    }
  },
  mamapadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Mama Padel',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Mama Padel',
      pathName: 'mamapadel',
      clubIds: ['444c33a5-0c73-4fc8-9213-c42dec41d47d'],
      countclub: 1,
      whiteLabelId: '6f9b92e6-0139-4f71-8ce5-c906f49835c5',
      useMatch: true
    }
  },
  indoor64: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Indoor 64',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Indoor 64',
      pathName: 'indoor64',
      clubIds: ['1ff9e295-f610-4f50-b09e-cec85dc599a9'],
      countclub: 1,
      whiteLabelId: '30733453-3f5a-4f44-bad6-5d16ed79d4dd',
      useMatch: true
    }
  },
  insport: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'In\'Sport',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'In\'Sport',
      pathName: 'insport',
      clubIds: ['a1c1683a-5b6a-4f63-bba6-b1f283fd6825'],
      countclub: 1,
      whiteLabelId: 'f699518f-ecf2-4f45-909c-ee247ca847e8',
      useMatch: true
    }
  },
  isatix: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Isatix',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Isatix',
      pathName: 'isatix',
      clubIds: ['4e9f239a-4e2b-4fdd-9f64-c31fc72d9319'],
      countclub: 1,
      whiteLabelId: '8b110f1f-abc6-4fbf-ac01-7fc8a0f4497a',
      useMatch: false
    }
  },
  kemp5: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Kemp\'5',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Kemp\'5',
      pathName: 'kemp5',
      clubIds: ['db3369a3-71bc-4ffc-a1bc-31f5b180514e'],
      countclub: 1,
      whiteLabelId: '075c98a7-4c4d-4f45-a813-ea7ff21c9dba',
      useMatch: true
    }
  },
  myfiit: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'MyFiit',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'MyFiit',
      pathName: 'myfiit',
      clubIds: ['5d187e70-cb83-4ff5-b92a-ab3173825d7d'],
      countclub: 1,
      whiteLabelId: '97ec3a2c-86f2-4ffa-9abc-02616659d360',
      useMatch: false
    }
  },
  modjoescalade: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Modjo Escalade',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Modjo Escalade',
      pathName: 'modjoescalade',
      clubIds: ['490f33a6-e68e-4f14-93dc-2c0bc702c176'],
      countclub: 1,
      whiteLabelId: '393c27bd-7dde-4f4b-890c-d262c8b5bc61',
      useMatch: false
    }
  },
  teampadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Team Padel',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Team Padel',
      pathName: 'teampadel',
      clubIds: ['5878b745-65d4-4f3b-9f1f-a00d35ca42a1'],
      countclub: 1,
      whiteLabelId: '0d8fa4bd-ef65-4fa6-b743-28252871c0de',
      useMatch: true
    }
  },
  leset: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Le Set',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Set',
      pathName: 'leset',
      clubIds: ['d7cb34bb-eb63-4f9f-8d53-caedfd4cd3eb'],
      countclub: 1,
      whiteLabelId: 'b4521c0c-30d7-4f5c-a6b7-bd8930c59e80',
      useMatch: true
    }
  },
  skycircus: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Sky Circus',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sky Circus',
      pathName: 'skycircus',
      clubIds: ['6886e433-5dab-4fc2-9f99-eb5ecf4dda5f'],
      countclub: 1,
      whiteLabelId: '1f208d0e-8b2f-4feb-9fc1-3401c14dfb95',
      useMatch: false
    }
  },
  elitesoccer: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Elite Soccer',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Elite Soccer',
      pathName: 'elitesoccer',
      clubIds: ['d81cecd7-19d5-4fb1-9038-5ace29b77a9a'],
      countclub: 1,
      whiteLabelId: '9e7ce57f-cde9-4fec-b26d-80708879729b',
      useMatch: true
    }
  },
  complexemanosque: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Le Complexe - Manosque',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Complexe - Manosque',
      pathName: 'complexemanosque',
      clubIds: ['7c154eb5-10a6-4fb3-a239-0fe084011940'],
      countclub: 1,
      whiteLabelId: '30c41df9-bada-4f4a-b4d1-8a535914198a',
      useMatch: true
    }
  },
  gardensoccer: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Garden Soccer',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Garden Soccer',
      pathName: 'gardensoccer',
      clubIds: ['89af5df3-aeec-4f9a-8d82-acb0fa779055'],
      countclub: 1,
      whiteLabelId: '8823893e-00cb-4fc6-81c8-2399b15eb4db',
      useMatch: true
    }
  },
  tccarros: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'TC Carros',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'TC Carros',
      pathName: 'tccarros',
      clubIds: ['510d1c9c-2f14-4fe7-b42a-48629e4651dc'],
      countclub: 1,
      whiteLabelId: '80847a91-d90b-4fb8-b972-8f95c0456320',
      useMatch: true
    }
  },
  padelriviera: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Padel Riviera',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Riviera',
      pathName: 'padelriviera',
      clubIds: ['5b3a0eb9-3565-4fc5-abd3-928f7f46dc14'],
      countclub: 1,
      whiteLabelId: '4be023d8-c33b-4fe5-bcbf-f4e2c51908e7',
      useMatch: true
    }
  },
  footgolf: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Sud-Ouest Foot Golf',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sud-Ouest Foot Golf',
      pathName: 'footgolf',
      clubIds: ['04d3e49e-0a7f-4fb5-a2ca-13ad462f1b1d'],
      countclub: 1,
      whiteLabelId: '5718c7cc-6c87-4fd5-ad1c-b51c10c17198',
      useMatch: false
    }
  },
  megaform: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Mega Form Apt',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Mega Form Apt',
      pathName: 'megaform',
      clubIds: ['b86be682-4576-4f94-89e3-2b47f74c4502'],
      countclub: 1,
      whiteLabelId: '9d848dce-e0a9-4f23-9826-51d0200e8117',
      useMatch: false
    }
  },
  padelbenevento: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: '4 Padel Benevento',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: '4 Padel Benevento',
      pathName: 'padelbenevento',
      clubIds: ['21ab3936-e962-4f5b-a789-56d9fe7c37a2'],
      countclub: 1,
      whiteLabelId: '741d2415-92d4-4f39-85ef-028d04d83c00',
      useMatch: true
    }
  },
  airsoccer: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Air Soccer',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Air Soccer',
      pathName: 'airsoccer',
      clubIds: ['0d818a4f-f9e3-4fb7-a5d8-08b72a688ed0'],
      countclub: 1,
      whiteLabelId: '7806a3a0-1079-4f43-97c6-26b35d3162de',
      useMatch: true
    }
  },
  allincountryclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'All In Country Club Grasse',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'All In Country Club Grasse',
      pathName: 'allincountryclub',
      clubIds: ['699d8822-4902-4ff5-b287-7331b11d926f'],
      countclub: 1,
      whiteLabelId: '5b4dea6f-bc6d-4fed-a78f-f5552b506d7c',
      useMatch: true
    }
  },
  arenaefitnessclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Arenae Fitness Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Arenae Fitness Club',
      pathName: 'arenaefitnessclub',
      clubIds: ['32a8807f-d26c-4fde-9024-ac29624bc887'],
      countclub: 1,
      whiteLabelId: 'f6ba2ae4-5d35-4f24-bfce-0815515ced7f',
      useMatch: false
    }
  },
  bunkscrossfit: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Bunks Crossfit',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Bunks Crossfit',
      pathName: 'bunkscrossfit',
      clubIds: ['aa43b892-6c28-4f54-96e2-ab88ea8bcab2'],
      countclub: 1,
      whiteLabelId: 'f5e47527-cb23-4fab-b958-c012713b33ce',
      useMatch: false
    }
  },
  classico5mons: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Classico 5 Mons',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Classico 5 Mons',
      pathName: 'classico5mons',
      clubIds: ['cb494d86-6631-4f88-a6dd-cc05ce044cc9'],
      countclub: 1,
      whiteLabelId: 'fa4f4508-ee41-4fc9-b9d9-1c825caaf35e',
      useMatch: true
    }
  },
  climbingmulhousecenter: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Climbing Mulhouse Center',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Climbing Mulhouse Center',
      pathName: 'climbingmulhousecenter',
      clubIds: ['5afbad4d-4362-4f5c-a434-165ac0b6c4f4'],
      countclub: 1,
      whiteLabelId: '647358cb-910e-4fe4-849b-1e6d857f44f1',
      useMatch: false
    }
  },
  clubladour: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Club de l\'Adour',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Club de l\'Adour',
      pathName: 'clubladour',
      clubIds: ['6a2fb820-448a-4fcf-a33a-70f2b4ce8aa9'],
      countclub: 1,
      whiteLabelId: '9d948fff-1a34-4f44-bd6d-a55b1874ff4e',
      useMatch: true
    }
  },
  complexesport5: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Complexe Sport 5',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Complexe Sport 5',
      pathName: 'complexesport5',
      clubIds: ['ec1b922b-6a7a-4fc0-ac06-afd3107e9153'],
      countclub: 1,
      whiteLabelId: '04e4e4ec-c03d-4f70-ac16-f13a8810387d',
      useMatch: true
    }
  },
  crossfitpezenas: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Crossfit Pezenas',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Crossfit Pezenas',
      pathName: 'crossfitpezenas',
      clubIds: ['b2ae1103-6d70-4f81-9058-44d56d0677d5'],
      countclub: 1,
      whiteLabelId: '55a05726-b83a-4fce-ba91-09e2538256a6',
      useMatch: false
    }
  },
  fitstudiosportclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Fitstudio by Sport Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Fitstudio by Sport Club',
      pathName: 'fitstudiosportclub',
      clubIds: ['ea29972d-2e2e-4f51-962b-cdf439bfe799'],
      countclub: 1,
      whiteLabelId: 'd14bfb51-02ab-4fa1-b207-3bd8b38ec3ac',
      useMatch: false
    }
  },
  harmonytime: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Harmony Time',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Harmony Time',
      pathName: 'harmonytime',
      clubIds: ['254fb8e9-da34-4fc9-95f6-2fd236ffac83'],
      countclub: 1,
      whiteLabelId: 'e9d78484-6df2-4f1c-8fc5-2added5ae454',
      useMatch: false
    }
  },
  herstalpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Herstal Padel Passion',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Herstal Padel Passion',
      pathName: 'herstalpadel',
      clubIds: ['b557aff1-ed0a-4f2a-8405-0fcdbfbf5021'],
      countclub: 1,
      whiteLabelId: '117e1fa3-c71a-4fc6-925d-61cbcb584c2b',
      useMatch: true
    }
  },
  kernup: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Kern\'Up',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Kern\'Up',
      pathName: 'kernup',
      clubIds: ['dc9e8346-a7fc-4f6e-8b37-c46d8a61e4e5'],
      countclub: 1,
      whiteLabelId: 'bb4ec1b8-1bd2-4f45-8181-79f0ffc604a8',
      useMatch: false
    }
  },
  idealclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'L\'ideal Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'L\'ideal Club',
      pathName: 'idealclub',
      clubIds: ['9f75ab6c-c930-4fff-bd1a-63865fb19645'],
      countclub: 1,
      whiteLabelId: 'f7c456d0-57cb-4f02-b12a-cd088aab6358',
      useMatch: false
    }
  },
  lehangar: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Le Hangar - Reunion',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Hangar - Reunion',
      pathName: 'lehangar',
      clubIds: ['8fb4f6bd-843d-4f02-ac79-b6e04497995c'],
      countclub: 1,
      whiteLabelId: '1012ae4a-7a49-4f49-b58b-12ba733dbc1d',
      useMatch: true
    }
  },
  lerocspot: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Le Roc Spot',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Roc Spot',
      pathName: 'lerocspot',
      clubIds: ['7c54aa3a-2e01-4f1f-a517-345e00723e35'],
      countclub: 1,
      whiteLabelId: '83c311f3-0094-4f4a-9764-ddef8d638798',
      useMatch: false
    }
  },
  templedufootdakar: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Le Temple du Foot Dakar',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Temple du Foot Dakar',
      pathName: 'templedufootdakar',
      clubIds: ['e5a700ea-a780-4f09-a8d7-24ebdf99c99d'],
      countclub: 1,
      whiteLabelId: 'a1d43828-2839-4f6b-9bfa-4a0fa90d14f4',
      useMatch: true
    }
  },
  libertyfitnesscoaching: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Liberty Fitness & Coaching',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Liberty Fitness & Coaching',
      pathName: 'libertyfitnesscoaching',
      clubIds: ['bdd4a51b-4bbe-4f37-a7b5-93729d01d0e0', '9842fdd7-2cbe-4fc7-9aba-13aaac0b1329', '6be69ad7-894c-4f1b-883c-fccf1b78168f'],
      countclub: 3,
      whiteLabelId: 'b0e300ac-30d1-4f26-be6d-80af5fc611bb',
      useMatch: false
    }
  },
  monfoot5: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Mont Foot 5 - Annecy',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Mont Foot 5 - Annecy',
      pathName: 'monfoot5',
      clubIds: [''],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  myfitnessteam: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'My Fitness Team',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'My Fitness Team',
      pathName: 'myfitnessteam',
      clubIds: [''],
      countclub: 1,
      whiteLabelId: '',
      useMatch: false
    }
  },
  o2bymta: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'O2 By MTA',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'O2 By MTA',
      pathName: 'o2bymta',
      clubIds: ['187aa6e0-52f5-4f9e-b10c-d616ee19b638', '39a9cbda-d751-4fe0-a37f-02f13ac6303b', '5555e7ce-4a97-4fc5-bbc6-2a64faa44591'],
      countclub: 3,
      whiteLabelId: 'ed84254d-4e93-4f7c-b2f9-298e9eec0655',
      useMatch: true
    }
  },
  padelnoumea: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Padel Noumea',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Noumea',
      pathName: 'padelnoumea',
      clubIds: ['3f300f27-7d2a-4f58-8e24-1df5dc09bb50'],
      countclub: 1,
      whiteLabelId: 'f5bf76d6-a33e-4f80-8d10-8d1eccf1be20',
      useMatch: true
    }
  },
  padelzone: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Padel Zone',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Zone',
      pathName: 'padelzone',
      clubIds: ['9e839444-ce0b-4f2a-aa5e-1f19781cf826'],
      countclub: 1,
      whiteLabelId: '7f2fc927-45c4-4f2d-b520-aa2bf4aac5de',
      useMatch: true
    }
  },
  pattedoie: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Patte d\'Oie Athletic Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Patte d\'Oie Athletic Club',
      pathName: 'pattedoie',
      clubIds: ['a05ceff8-8d3c-4f83-8200-ba4656aea833'],
      countclub: 1,
      whiteLabelId: '1c4a105b-160f-4fa3-a0d2-96c1cd10e40d',
      useMatch: true
    }
  },
  santiloclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Santilo Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'ma',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Santilo Club',
      pathName: 'santiloclub',
      clubIds: ['a51612d7-8042-4f4e-b2bb-4eb9bd5f58c3'],
      countclub: 1,
      whiteLabelId: '8e4a3bed-ed15-4f46-9124-95bdef5e07cc',
      useMatch: true
    }
  },
  seraingtc: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Seraing Tennis Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Seraing Tennis Club',
      pathName: 'seraingtc',
      clubIds: ['51c508da-2b04-4f72-a6b8-3e7817f95a8d'],
      countclub: 1,
      whiteLabelId: '41203236-6b67-4f0e-a5de-74170c320e5b',
      useMatch: true
    }
  },
  spartiategym: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Spartiate Gym',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Spartiate Gym',
      pathName: 'spartiategym',
      clubIds: ['4c678a4a-4924-4f38-8cc2-19eef2392ca6'],
      countclub: 1,
      whiteLabelId: '2280b211-506e-4f20-a5b9-a3e01a7ca2bf',
      useMatch: false
    }
  },
  stclimbing: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'ST Climbing',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'ST Climbing',
      pathName: 'stclimbing',
      clubIds: ['4fa283ee-2141-4fe9-ac93-4bed9b014664'],
      countclub: 1,
      whiteLabelId: '3fc4f213-932f-4f77-9694-b0b5e38905e5',
      useMatch: false
    }
  },
  ringnicois: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Ring Niçois',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Ring Niçois',
      pathName: 'ringnicois',
      clubIds: ['4b5c230b-95ad-4fa7-9494-9176f52627cc'],
      countclub: 1,
      whiteLabelId: 'f0cd52c8-5d52-4fd8-a966-e0cc801e0345',
      useMatch: false
    }
  },
  laroqgym: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Laroq Gym Hyères',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Laroq Gym Hyères',
      pathName: 'laroqgym',
      clubIds: ['9eb3113a-1d78-4f39-bb60-f94f61b3bd04'],
      countclub: 1,
      whiteLabelId: 'dae558a9-ba46-4f79-93dd-74c7a6b93749',
      useMatch: false
    }
  },
  tcvalrose: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Padel Tennis Nice Valrose',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Tennis Nice Valrose',
      pathName: 'tcvalrose',
      clubIds: ['a707f152-68be-4f3b-8836-34fb7aae674f'],
      countclub: 1,
      whiteLabelId: 'feae459b-edd3-4f3b-ac65-9e15d320ba58',
      useMatch: true
    }
  },
  retcl: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Royal Excelsior Tennis Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'be',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Royal Excelsior Tennis Club',
      pathName: 'royalexcelsiortennisclub',
      clubIds: ['696822fb-73dd-4f74-9dda-f8a54fb93219'],
      countclub: 1,
      whiteLabelId: '9c754db3-fed2-4f65-8d0f-42019898e0e9',
      useMatch: true
    }
  },
  crossfitatalante: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Crossfit Atalante',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Crossfit Atalante',
      pathName: 'crossfitatalante',
      clubIds: ['7c9ba53c-4d6d-4f58-a0d1-aff9a152dacf'],
      countclub: 1,
      whiteLabelId: '17a02f01-d605-4fa3-b9ec-1317c65ea035',
      useMatch: false
    }
  },
  agenindoor: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '181ca245-8222-4ee1-b299-9a6404cfcbe0',
    oneSignalGgl: '491093722550',
    branchIOKey: 'key_live_knWPvl6CirXQ6GtZy2aZqgcfxscRUHHJ',
    nameApp: 'Agen Indoor',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.6.5',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Agen Indoor',
      pathName: 'agenindoor',
      clubIds: ['346fc615-3a08-4f9a-8db2-0d62cfc84eee'],
      countclub: 1,
      whiteLabelId: 'c2329e72-33b5-4fb9-a65e-f11624bfcc35',
      useMatch: true
    }
  },
  nickisurf: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Nicki Surf',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Nicki Surf',
      pathName: 'nickisurf',
      clubIds: ['5eac906d-eb5f-4f01-b0ec-48bbc5b2dfe2'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: false
    }
  },
  rabatanimation: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Rabat Animation',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'mc',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Rabat Animation',
      pathName: 'rabatanimation',
      clubIds: ['c2944eff-6282-4fd1-9360-7af0a886f24e'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  edenyasport: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Edenya Sport',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Edenya Sport',
      pathName: 'edenyasport',
      clubIds: ['4d209c48-7cc2-4f8a-869a-fb6ec22669f0'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  foottime: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Foot time Echirolles',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Foot time Echirolles',
      pathName: 'foottime',
      clubIds: ['b781c6b7-1ad6-4f80-948b-249961f4a9f5'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  onlypaintballnord: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Only Paintball Lyon Nord',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Only Paintball Lyon Nord',
      pathName: 'onlypaintballnord',
      clubIds: ['f6d600f0-9307-4fd8-81dc-7d352fc48c19'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  onlypaintball: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Only Paintball Lyon Sud',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Only Paintball Lyon Sud',
      pathName: 'onlypaintball',
      clubIds: ['c491a3fb-6c0a-4ffd-b8a9-a37568718880'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  clubprovencetirarbois: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Club Provence Tir Arbois',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Club Provence Tir Arbois',
      pathName: 'clubprovencetirarbois',
      clubIds: ['631ce2d1-434a-4f54-bbab-462585d91e03'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  osmose: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Osmose Studio',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Osmose Studio',
      pathName: 'osmose',
      clubIds: ['47c57b8f-28c9-4f42-a0c1-dc357d6a947d'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  sabrinapilate: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Sabrina Fitness Pilates',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sabrina Fitness Pilates',
      pathName: 'sabrinapilate',
      clubIds: ['bdda71d7-17b4-4fa3-9fc4-1bef9776e4ed'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  joinville: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'CC Joinville',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'CC Joinville',
      pathName: 'joinville',
      clubIds: ['bf0ee88a-2cc0-4f71-80e5-b8d2a89deb06'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  yournature: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Your Nature',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Your Nature',
      pathName: 'yournature',
      clubIds: ['81e8b50e-b82b-4f4d-babd-4ab56cc4b83d'],
      countclub: 1,
      whiteLabelId: 'bd048713-f9db-4f02-b049-4e82ab81a2ce',
      useMatch: false
    }
  },
  savateboxing: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Savate Boxing Endoume',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Savate Boxing Endoume',
      pathName: 'savateboxing',
      clubIds: ['a79b58bd-c580-4ff1-89e5-9d5523d448c3'],
      countclub: 1,
      whiteLabelId: 'bd3f9f1c-0a82-4f2d-8d57-ef888253fa15',
      useMatch: false
    }
  },
  enjoythegame: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Enjoy the Game',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Enjoy the Game',
      pathName: 'enjoythegame',
      clubIds: ['1b22d378-2f9e-4f43-9de8-278241c42096'],
      countclub: 1,
      whiteLabelId: '6f6244d7-f684-4f63-b1bd-5b3f1954c5ff',
      useMatch: false
    }
  },
  capadapt: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'CAP\'ADAPT',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'CAP\'ADAPT',
      pathName: 'capadapt',
      clubIds: ['47ebb602-9110-4ff2-9667-1361b571f6fe'],
      countclub: 1,
      whiteLabelId: '5b5c0132-31ee-4f72-8e31-905e435d4a55',
      useMatch: false
    }
  },
  arsoccer: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'AR Soccer',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.6.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'AR Soccer',
      pathName: 'arsoccer',
      clubIds: ['2bfadb7f-4501-4f18-9cf3-c5c0a09ce34e'],
      countclub: 1,
      whiteLabelId: 'a176b0cc-04ca-4f8f-b0cc-7ddb24146376',
      useMatch: false
    }
  },
  newjumprennes: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'New Jump Rennes',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'New Jump Rennes',
      pathName: 'newjump',
      clubIds: ['5e077d8b-07ff-4f9e-8834-a21e20523d6a'],
      countclub: 1,
      whiteLabelId: '10287afe-4cc3-4f7e-8df6-3b899d320338',
      useMatch: false
    }
  },
  newjumplangon: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'New Jump Langon',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'New Jump Langon',
      pathName: 'newjump',
      clubIds: ['937265bd-6a65-4f8f-96b0-1d2791f28599'],
      countclub: 1,
      whiteLabelId: '10287afe-4cc3-4f7e-8df6-3b899d320338',
      useMatch: false
    }
  },
  newjumpsaumur: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'New Jump Saumur',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'New Jump Saumur',
      pathName: 'newjump',
      clubIds: ['d0a4fa55-68d5-4fef-b169-eb3f03e6adc6'],
      countclub: 1,
      whiteLabelId: '10287afe-4cc3-4f7e-8df6-3b899d320338',
      useMatch: false
    }
  },
  newjump: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'New Jump',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'New Jump',
      pathName: 'newjump',
      clubIds: ['5e077d8b-07ff-4f9e-8834-a21e20523d6a', 'd0a4fa55-68d5-4fef-b169-eb3f03e6adc6', '937265bd-6a65-4f8f-96b0-1d2791f28599'],
      countclub: 3,
      whiteLabelId: '10287afe-4cc3-4f7e-8df6-3b899d320338',
      useMatch: false
    }
  },
  theloft: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'The Loft - Luxembourg',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'The Loft - Luxembourg',
      pathName: 'theloft',
      clubIds: ['07c017ef-3251-4fef-af35-c548400db1bb'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: false
    }
  },
  msquash: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'M Squash',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'M Squash',
      pathName: 'msquash',
      clubIds: ['a0363867-c814-4fda-adf2-615ea370e700'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: false
    }
  },
  insidesport: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Inside Sport',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Inside Sport',
      pathName: 'insidesport',
      clubIds: ['6ad97df0-a339-4f8f-b1d3-ab334520eef3'],
      countclub: 1,
      whiteLabelId: '15f69728-2a68-4f6c-a9fc-2017c84ef591',
      useMatch: false
    }
  },
  aixnride: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Aix n\' Ride',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Aix n\' Ride',
      pathName: 'aixnride',
      clubIds: ['c1a59fb1-56f2-4f11-aa5b-53b5e83b18c9'],
      countclub: 1,
      whiteLabelId: '941e008f-c066-4fcf-80f1-289e60f87842',
      useMatch: false
    }
  },
  angersplayground: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Angers Playground',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Angers Playground',
      pathName: 'angersplayground',
      clubIds: ['90d96dd1-70cb-4fcc-ad88-b1efe5068e87'],
      countclub: 1,
      whiteLabelId: 'dd8418c9-86c1-4f7d-9a1c-c2ba0d5072b6',
      useMatch: true
    }
  },
  scofootsal: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Angers SCO Footsal',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Angers SCO Footsal',
      pathName: 'scofootsal',
      clubIds: ['1ce714b4-b221-4fe3-84c5-f05beeb14b0a'],
      countclub: 1,
      whiteLabelId: 'ae8a8a57-a4b9-4f74-8719-85b8e8483dfe',
      useMatch: true
    }
  },
  sbchartres: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'ASB Chartres',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'ASB Chartres',
      pathName: 'sbchartres',
      clubIds: ['8dc78d5a-2bfd-4f50-9abc-a3e34a5d2c82'],
      countclub: 1,
      whiteLabelId: 'da7e653a-bfc4-4f55-b51d-0f59d46d20f7',
      useMatch: true
    }
  },
  aspnat34: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'ASP Natation 34',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'ASP Natation 34',
      pathName: 'aspnat34',
      clubIds: ['798003ca-5155-4f6e-a130-1f91e21b81e1'],
      countclub: 1,
      whiteLabelId: '9da42d5e-78e5-4f73-8907-79a91fd489e4',
      useMatch: false
    }
  },
  bayardsclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Bayards Sports',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Bayards Sports',
      pathName: 'bayardsclub',
      clubIds: ['49168acd-0bc0-4f0b-b98b-84a6b768743e'],
      countclub: 1,
      whiteLabelId: '5b508eec-62d1-4f3b-a81b-13a48e810378',
      useMatch: true
    }
  },
  bodyfit88: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Body Fit 88',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'en',
    defaultPhoneLang: 'th',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Body Fit 88',
      pathName: 'bodyfit88',
      clubIds: ['c79d1598-5e84-4fa9-ac40-42f180e0d4d5'],
      countclub: 1,
      whiteLabelId: '8b37c2ec-febd-4f2b-a191-f9201d6d0662',
      useMatch: false
    }
  },
  club5coaching: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Club 5 coaching',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Club 5 coaching',
      pathName: 'club5coaching',
      clubIds: ['e16ecf64-a47f-4fe8-917c-dfccee98c46f'],
      countclub: 1,
      whiteLabelId: '2dc80db8-1770-4feb-a0cb-080bcf6e3b58',
      useMatch: false
    }
  },
  conceptball: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Concept Ball',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Concept Ball',
      pathName: 'conceptball',
      clubIds: ['41f7bdcb-9fe5-4fb4-b195-a1be8115fb59'],
      countclub: 1,
      whiteLabelId: '40c2782c-21b4-4f18-8ca6-1b67b230cb30',
      useMatch: true
    }
  },
  endemikclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Endemik Club',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Endemik Club',
      pathName: 'endemikclub',
      clubIds: ['5a467044-fa26-4f97-a582-0bf98de8db9b'],
      countclub: 1,
      whiteLabelId: '02f1f992-4bad-4f7c-a4d4-069eda0cd5e3',
      useMatch: true
    }
  },
  Esperia: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Esperia Volleyball Academy',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Esperia Volleyball Academy',
      pathName: 'Esperia',
      clubIds: ['35d96939-47e1-4fac-a3ec-3a1e0dec78f7'],
      countclub: 1,
      whiteLabelId: 'b6b4117c-303c-4fad-a326-2cec3c6c45ad',
      useMatch: true
    }
  },
  fitnessclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Fitness Club',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Fitness Club',
      pathName: 'fitnessclub',
      clubIds: ['7a1e1812-b73a-4f7d-b4ac-91e1a5370b62'],
      countclub: 1,
      whiteLabelId: 'fd312cd7-ad1e-4f09-a1b4-cf48db06d85e',
      useMatch: false
    }
  },
  indoorgames: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Indoor Games',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Indoor Games',
      pathName: 'indoorgames',
      clubIds: ['ec22cb5a-7bfa-4fd5-9724-23dc378025d4'],
      countclub: 1,
      whiteLabelId: '288224a2-d6bb-4f3b-b978-0c8559f80f71',
      useMatch: true
    }
  },
  kazapadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'KAZ A PADEL',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'KAZ A PADEL',
      pathName: 'kazapadel',
      clubIds: ['4235e618-6da4-4f50-aacc-182b246ee727'],
      countclub: 1,
      whiteLabelId: 'e6735642-f19f-4f26-8a97-7cd76997ba37',
      useMatch: true
    }
  },
  keepcoolna: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Keep Cool Nimes & Alès',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Keep Cool Nimes & Alès',
      pathName: 'keepcoolna',
      clubIds: ['f02ae2f0-da30-4fa1-b541-01b0e053434c', 'a668fa13-e7d0-4f6a-a8fa-e472d7208f66', '7a8d9f79-0e27-4f88-b19d-b2508392fc8e', '5c6e0372-0403-4f23-b723-8556f3f435ec', '9131bad9-b3e9-4fcf-9acf-43b83b84058a'],
      countclub: 5,
      whiteLabelId: '9774d29a-8c9e-4f9a-8638-631374e52408',
      useMatch: false
    }
  },
  myfitcoaching: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'My Fit Coaching',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'My Fit Coaching',
      pathName: 'myfitcoaching',
      clubIds: ['d7d1efdf-2017-4f7f-8d48-2d9f59fb8827'],
      countclub: 1,
      whiteLabelId: '5fe610c6-947d-4f9f-ba47-27e98c3c339d',
      useMatch: false
    }
  },
  padball: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Pad&Ball',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Pad&Ball',
      pathName: 'padball',
      clubIds: ['b9033593-37ea-4fe8-a26e-1d789c3bd069'],
      countclub: 1,
      whiteLabelId: 'aa93e156-e8b2-4f93-872a-166fdf9d787d',
      useMatch: true
    }
  },
  beausoleil: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Padel Club Beausoleil',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Club Beausoleil',
      pathName: 'beausoleil',
      clubIds: ['e39bda4b-09db-4f0c-bcaa-8513faf77182'],
      countclub: 1,
      whiteLabelId: '152961f5-85c4-4f5f-a3c5-8de3b1729521',
      useMatch: true
    }
  },
  planetpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Planet Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Planet Padel',
      pathName: 'planetpadel',
      clubIds: ['4eff1248-6a6f-4f81-8233-69723c720f99'],
      countclub: 1,
      whiteLabelId: '48a8e745-efdc-4fa5-9984-44abfae8fb75',
      useMatch: true
    }
  },
  soccercity: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Soccer City',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Soccer City',
      pathName: 'soccercity',
      clubIds: ['a6a8571f-0414-4f58-8a71-582d0d9acc6d'],
      countclub: 1,
      whiteLabelId: 'fd8a9e59-dcf5-4f3e-9466-18ee24f3caee',
      useMatch: true
    }
  },
  southwakepark: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'South Wake Park',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'South Wake Park',
      pathName: 'southwakepark',
      clubIds: ['d6cac6a5-023d-4fac-8343-246f6efa1441'],
      countclub: 1,
      whiteLabelId: 'f6f0558a-756b-4f65-8a5a-3b202992373a',
      useMatch: false
    }
  },
  Sparkbodyfit: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Spark Bodyfit Pertuis',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Spark Bodyfit Pertuis',
      pathName: 'Sparkbodyfit',
      clubIds: ['f4a04949-6e69-4fb7-b634-c1bea6bbbf9f'],
      countclub: 1,
      whiteLabelId: '0fe4892e-151a-4f8a-ad05-cee6de7cc063',
      useMatch: false
    }
  },
  sportmedic: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Sport Medic',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sport Medic',
      pathName: 'sportmedic',
      clubIds: ['5625db2c-306c-4f74-8174-cd15c574818e'],
      countclub: 1,
      whiteLabelId: '741e5b50-fd0a-4f73-92ca-e387dd7980d6',
      useMatch: true
    }
  },
  taekwondo76: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Taekwondo Elite 76',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Taekwondo Elite 76',
      pathName: 'taekwondo76',
      clubIds: ['0790f4e8-2088-4fba-92e8-8b7b73ec64ce'],
      countclub: 1,
      whiteLabelId: 'be2113b3-7a12-4f84-a1d9-4139e500f5ab',
      useMatch: false
    }
  },
  tccarpentras: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'TC Carpentras',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'TC Carpentras',
      pathName: 'tccarpentras',
      clubIds: ['39270760-360f-4fcb-bbb1-73bd946eac33'],
      countclub: 1,
      whiteLabelId: '01b9453c-5aeb-4f19-95b7-d151a6299b08',
      useMatch: true
    }
  },
  tcacacias: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'TC des Acacias',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'TC des Acacias',
      pathName: 'tcacacias',
      clubIds: ['d484cf2e-5d60-4f47-bb37-290e420b8bfa'],
      countclub: 1,
      whiteLabelId: 'b3f2d3f9-315a-4fdd-a510-bb913b4c4065',
      useMatch: true
    }
  },
  teams5amiens: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Team 5',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Team 5',
      pathName: 'teams5amiens',
      clubIds: ['d29b727f-ebc9-4f07-8736-2c2a357169de'],
      countclub: 1,
      whiteLabelId: '88f7f496-674b-4f69-ade6-4fbcc9a8f4cd',
      useMatch: true
    }
  },
  tcam: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Tennis Club Amiens Métropole - Padel & Badminton',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Tennis Club Amiens Métropole - Padel & Badminton',
      pathName: 'tcam',
      clubIds: ['678dc3bb-d208-4fee-8920-6b4141d8f35e'],
      countclub: 1,
      whiteLabelId: 'e3c695a7-f5a1-4f45-86d6-203e728f595f',
      useMatch: true
    }
  },
  ultra: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'ULTRA',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'ULTRA',
      pathName: 'ultra',
      clubIds: ['d27bb94f-ae77-4f02-add0-1b2077d11867', 'dcf718d0-4df4-4ff8-acc4-3221470f8075', '760dd287-443b-4f98-bd02-bec7f33731ec', '55cd57ff-df21-4fbb-85a2-62052703fa73', '10f7825e-b6a2-4fd4-a7b6-1e4069ec6a9d', 'afb807fb-3700-4f73-96e9-de987f26aa4d'],
      countclub: 6,
      whiteLabelId: 'd77583d1-defd-4fe6-96ff-a0153a21bd73',
      useMatch: true
    }
  },
  urbanpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Urban Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Urban Padel',
      pathName: 'urbanpadel',
      clubIds: [''],
      countclub: 1,
      whiteLabelId: '5daffdb0-9187-4fa5-8055-742854d1fecc',
      useMatch: true
    }
  },
  urbanroundnet: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'UrbanRoundNet',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'UrbanRoundNet',
      pathName: 'urbanroundnet',
      clubIds: ['9c76f7ae-7cb7-4f41-8340-df8d13e57af7'],
      countclub: 1,
      whiteLabelId: 'da1e2249-7487-4f92-a273-8367d8ae4629',
      useMatch: true
    }
  },
  versusgaming: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Versus Gaming Center',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Versus Gaming Center',
      pathName: 'versusgaming',
      clubIds: ['23cc77fb-b290-4f83-9224-40c94c482580'],
      countclub: 1,
      whiteLabelId: 'f8a38661-067d-4f9a-a44d-d77dc367ae3d',
      useMatch: false
    }
  },
  wakeupsquash: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Wake Up Squash Angers',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Wake Up Squash Angers',
      pathName: 'wakeupsquash',
      clubIds: ['63afd3cf-0917-4f1b-bad7-788cb66b1431'],
      countclub: 1,
      whiteLabelId: '11fa0d60-ab3b-4f08-b012-b74942358ef1',
      useMatch: false
    }
  },
  wellness: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Wellness Centre mon Idéal',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Wellness Centre mon Idéal',
      pathName: 'wellness',
      clubIds: ['7684bc97-07b6-4f3c-8a29-5f65395ee44b'],
      countclub: 1,
      whiteLabelId: '1eb3647f-2311-4fd4-b075-c4d8874f4518',
      useMatch: false
    }
  },
  wowpark: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Wow Park',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Wow Park',
      pathName: 'wowpark',
      clubIds: ['581c00f0-653c-4f95-9ada-d02e515eccc0'],
      countclub: 1,
      whiteLabelId: '359f0359-321f-4f7c-be5e-098ac3c7e039',
      useMatch: false
    }
  },












  soccerrennais: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Soccer Rennais',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Soccer Rennais',
      pathName: 'soccerrennais',
      clubIds: ['348d19e8-95d5-4ffa-880d-8462b832b4ad'],
      countclub: 1,
      whiteLabelId: '7f601a11-b1ca-4fad-be29-28c3995ae6f3',
      useMatch: true
    }
  },
  wellputt: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Wellputt',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Wellputt',
      pathName: 'wellputt',
      clubIds: ['7b55e61d-dc05-4f9f-ae8f-44d8a9040fad'],
      countclub: 1,
      whiteLabelId: '9ac3770a-68e9-4fb9-9029-00b548bc831f',
      useMatch: false
    }
  },
  studioarelo: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Studio Arelo',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Studio Arelo',
      pathName: 'studioarelo',
      clubIds: ['4a555f28-a13b-4f53-ad77-86b9618902fb'],
      countclub: 1,
      whiteLabelId: '7dc27d1c-b0c1-4f00-9e89-3526e6f22d9f',
      useMatch: false
    }
  },
  murmur: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Le Mur Mur',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Mur Mur',
      pathName: 'murmur',
      clubIds: ['79cd32e6-9e94-4f14-a98a-d27c96326a5e'],
      countclub: 1,
      whiteLabelId: '0bbca577-5186-4fb3-a20a-7d7f37e4daf8',
      useMatch: true
    }
  },
  sport4lux: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Sport 4 Lux',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sport 4 Lux',
      pathName: 'sport4lux',
      clubIds: ['e485699e-f30e-4fea-b2f7-7750d4ca7808'],
      countclub: 1,
      whiteLabelId: 'e422671c-aa28-4fa2-b947-0d9836d9ff3e',
      useMatch: true
    }
  },
  originemartialarts: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Origine Martial Arts',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Origine Martial Arts',
      pathName: 'originemartialarts',
      clubIds: ['92b0b11e-61e2-4ff0-ba98-d4c4d4877e10'],
      countclub: 1,
      whiteLabelId: 'f613ea01-c7f0-4ff9-a7c8-4e9bcb44502d',
      useMatch: true
    }
  },
  "pass-1": {
    production: true,
    developpement: false,
    domainAPI: 'https://preprod-v3.doinsport.club',
    pathFiles: 'https://preprod-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Demo préprod',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_test_6OSmkMCmSKfG9gUumNwt7gEC00RcsRUtao',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Demo préprod',
      pathName: 'Demo préprod',
      clubIds: ['9a05a224-9544-4f11-93db-693d9343b485', '9d5fbc8f-8651-4fd7-9b39-a32f245e89af'], // club démo devs
      countclub: 2,
      whiteLabelId: 'cd8e41f0-294d-4ff6-ac25-58d7f071a9d4',
      useMatch: true
    }
  },
  basketcenter: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Basket Center',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Basket Center',
      pathName: 'basketcenter',
      clubIds: ['e6fef0c3-4807-4f8a-bcae-fe2d3f27eb21'],
      countclub: 1,
      whiteLabelId: 'f707db7f-2047-4f70-a6d4-36bae4f467e4',
      useMatch: true
    }
  },
  punchclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: 'f580b608-1943-472c-8f53-4bb4221d06b0',
    oneSignalGgl: '1030333601831',
    branchIOKey: 'key_live_bnQHH0fqLU4qudo2aSs6nlhouweaWAFw',
    nameApp: 'Punch Club',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.0.9',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Punch Club',
      pathName: 'punchclub',
      clubIds: ['7dd730ba-1817-4fbc-bc38-003525cd1b97'],
      countclub: 1,
      whiteLabelId: 'e8d631a4-63fd-4f2f-b046-618bc7041ef1',
      useMatch: false
    }
  },
  "padelshot-stetienne": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: 'f580b608-1943-472c-8f53-4bb4221d06b0',
    oneSignalGgl: '1030333601831',
    branchIOKey: 'key_live_bnQHH0fqLU4qudo2aSs6nlhouweaWAFw',
    nameApp: 'Padel Shot - St Etienne',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.0.9',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Shot - St Etienne',
      pathName: 'padelshot',
      clubIds: ['613e7373-4804-4f12-aef8-0cf07b4ebecf'],
      countclub: 1,
      whiteLabelId: '8f9e1980-1e8c-4fd4-a81c-41d3859d3efb',
      useMatch: true
    }
  },
  "padelshot-caen": {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: 'f580b608-1943-472c-8f53-4bb4221d06b0',
    oneSignalGgl: '1030333601831',
    branchIOKey: 'key_live_bnQHH0fqLU4qudo2aSs6nlhouweaWAFw',
    nameApp: 'Padel Shot - Caen',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.0.9',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Shot - Caen',
      pathName: 'padelshot',
      clubIds: ['93430e85-c51e-4fab-9c30-f0e490e5fdc6'],
      countclub: 1,
      whiteLabelId: '8f9e1980-1e8c-4fd4-a81c-41d3859d3efb',
      useMatch: true
    }
  },
  montfoot5: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '870e1c8e-320d-46c1-bbef-96561557e23b',
    oneSignalGgl: '94567577105',
    branchIOKey: 'key_live_loORPPOD5UulFJtmfn7W9oabxAnZs1Mz',
    nameApp: 'Mont Foot 5',
    bookingOrigin: 'white_label_app',
    tabbarCountItems: 5,
    versionApp: '3.0.9',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Mont Foot 5',
      pathName: 'montfoot5',
      clubIds: ['a79aef15-e51e-4f46-b8dc-9e759d667352'],
      countclub: 1,
      whiteLabelId: 'd55e38be-1f53-4f16-9184-c19c1ecc49e7',
      useMatch: true
    }
  },
  vistasante: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Vista Santé',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Vista Santé',
      pathName: 'vistasante',
      clubIds: ['e7b05356-fba6-4fa8-8ddb-5d49b4c310f7'],
      countclub: 1,
      whiteLabelId: '9fadb875-d38f-4fa6-a591-e50b54d14573',
      useMatch: false
    }
  },
  saintquentinmesnil: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Saint Quentin Mesnil',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Saint Quentin Mesnil',
      pathName: 'saintquentinmesnil',
      clubIds: ['6af6e6a2-bc42-4f89-93b8-1cf5b84f0d8d'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  sportsgalaxy: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Sport Galaxy',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'sn',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sport Galaxy',
      pathName: 'sportsgalaxy',
      clubIds: ['2bec86af-0dd2-4f03-be2d-95329df16431'],
      countclub: 1,
      whiteLabelId: '9fa2d8e4-10c5-4f09-8b94-78483d61416c',
      useMatch: true
    }
  },
  klubnco: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Klub N Co',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Klub N Co',
      pathName: 'klubnco',
      clubIds: ['d8d72fc5-816c-4fe8-81b8-f097395cde26', '074af3bc-d11a-4f2c-a9de-03231dd58e41', '2f0c389f-744f-4fc0-bbe2-e83ca7aa907d', '32150620-8f72-4f76-98c2-b4574540cf14', 'c914c114-0111-4ff5-8464-3f6363cea187', 'ec70832f-57e2-4f4e-8083-ffd608e7ad0f', 'b68bd42b-a54d-4fa1-ba54-e030707ccbc0', '1ab3470f-94be-4fb5-8520-fe9c85b366be', '86f5e407-1996-4f87-9e45-745f0d616dd3'],
      countclub: 9,
      whiteLabelId: 'c862ca13-3bfd-4f57-8eea-bf23b6aee25b',
      useMatch: true
    }
  },
  royalboxingclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Royal Boxing Club',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Royal Boxing Club',
      pathName: 'royalboxingclub',
      clubIds: ['43a719c3-658b-4f0a-be53-87d77398b896'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: false
    }
  },
  redzone: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Red Zone',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Red Zone',
      pathName: 'redzone',
      clubIds: ['4e448914-4f39-4f10-b80b-b1596e74072d'],
      countclub: 1,
      whiteLabelId: '081297b5-e17b-4f01-86d1-2e8a8a3ad977',
      useMatch: true
    }
  },
  lamaisondusquash: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'La Maison du Squash',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'La Maison du Squash',
      pathName: 'lamaisondusquash',
      clubIds: ['ba6f4fc9-8afd-4fb7-b33e-f86d90e4cca7'],
      countclub: 1,
      whiteLabelId: 'f5cfe775-8f09-4f03-ae6e-060ebf48a018',
      useMatch: true
    }
  },
  casuarina: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Casuarina',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Casuarina',
      pathName: 'casuarina',
      clubIds: ['5555e7ce-4a97-4fc5-bbc6-2a64faa44591'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  undergroundboxingclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Underground Boxing Club',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Underground Boxing Club',
      pathName: 'undergroundboxingclub',
      clubIds: ['6a4ccb5e-27ff-4fa1-96b6-937592355659'],
      countclub: 1,
      whiteLabelId: '27abf50c-3bfd-4f60-8de3-600e8d0dd68d',
      useMatch: false
    }
  },
  spooncenter: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Spoon Center',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Spoon Center',
      pathName: 'spooncenter',
      clubIds: ['73a48272-6097-4f77-ba9f-dc14e4b8568b'],
      countclub: 1,
      whiteLabelId: '5eb4bf84-c43a-4f54-8094-282d4c933c26',
      useMatch: true
    }
  },
  mtaazuri: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Mauritius Tennis Academy Azuri',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Mauritius Tennis Academy Azuri',
      pathName: 'mtaazuri',
      clubIds: ['39a9cbda-d751-4fe0-a37f-02f13ac6303b'],
      countclub: 1,
      whiteLabelId: 'ed84254d-4e93-4f7c-b2f9-298e9eec0655',
      useMatch: true
    }
  },
  cdsquash93: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Comité Départemental de Squash 93',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Comité Départemental de Squash 93',
      pathName: 'cdsquash93',
      clubIds: ['0a86ed03-1ec8-4f60-b96a-6c9e091b2f2d'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  coach4you: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Coach4You',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Coach4You',
      pathName: 'coach4you',
      clubIds: ['d563c893-b7ea-4f17-bca7-fe10d1fb79e8'],
      countclub: 1,
      whiteLabelId: '1d0f2dcd-6330-4fec-8ae3-8eed0e2e5e83',
      useMatch: false
    }
  },
  crossfithaekkun: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Crossfit Haekkun',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Crossfit Haekkun',
      pathName: 'crossfithaekkun',
      clubIds: ['c9fc2a0e-6372-4f7f-a467-b3ff3b83be30'],
      countclub: 1,
      whiteLabelId: '04681a66-a47b-4fda-8aa8-0ef38e434593',
      useMatch: false
    }
  },
  mfitstudio: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'M\'Fit Studio',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'M\'Fit Studio',
      pathName: 'mfitstudio',
      clubIds: ['f2aafc01-a7f0-4f4c-ac0e-19f598d8b482'],
      countclub: 1,
      whiteLabelId: 'e983027a-f0c8-4f26-be0d-dbbc45cc3534',
      useMatch: false
    }
  },
  Studiokdanse: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Studio K Danse',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Studio K Danse',
      pathName: 'Studiokdanse',
      clubIds: ['75a88171-4241-4f87-a3be-de59f768882c'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: false
    }
  },
  athleticsclub: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Athletics Club',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Athletics Club',
      pathName: 'athleticsclub',
      clubIds: ['75a88171-4241-4f87-a3be-de59f768882c'],
      countclub: 1,
      whiteLabelId: '7b8c89bc-ddf8-4fb4-b1b5-2432d5c15860',
      useMatch: false
    }
  },
  darioritumcrossfit: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Darioritum Crossfit',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Darioritum Crossfit',
      pathName: 'darioritumcrossfit',
      clubIds: ['a2fa7e52-9d87-4f15-a691-85a84cdcad97'],
      countclub: 1,
      whiteLabelId: '0b6d0561-c33e-4fdf-8fd9-d01c2abc5ebe',
      useMatch: false
    }
  },
  dkpark: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'DK Park',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'DK Park',
      pathName: 'dkpark',
      clubIds: ['7e8a4928-73aa-4f64-ba5b-db9a35ba87e0'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  gingafoot: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Ginga Foot',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Ginga Foot',
      pathName: 'gingafoot',
      clubIds: ['1b699512-a5b5-4f5a-a101-fa7e4c19ba7e'],
      countclub: 1,
      whiteLabelId: 'af54e5b1-f4b6-4fa0-b32b-a043b96785d8',
      useMatch: true
    }
  },
  tcsaintes: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Tennis Club Saintes',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Tennis Club Saintes',
      pathName: 'tcsaintes',
      clubIds: ['3586752d-7fd4-4fa1-8290-fa5a22630a52'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  wakebeach34: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Wake Beach',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Wake Beach',
      pathName: 'wakebeach34',
      clubIds: ['7d17b3a4-fd79-4fa0-bee0-6cd5e357838f'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  kipstadium: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Kipstadium',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Kipstadium',
      pathName: 'kipstadium',
      clubIds: ['7d17b3a4-fd79-4fa0-bee0-6cd5e357838f'],
      countclub: 1,
      whiteLabelId: 'ac98604f-2d96-4ff5-899a-4d17a45d9676',
      useMatch: true
    }
  },
  clubprovencetir: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Association Club Provence Tir',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Association Club Provence Tir',
      pathName: 'clubprovencetir',
      clubIds: ['e3bb0712-cde9-4fc3-b940-0bdec88688f0'],
      countclub: 1,
      whiteLabelId: 'a55489fa-d8fb-4f7a-89e5-412abe5a9aff',
      useMatch: true
    }
  },
  arvegiffrehandball: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Arve Giffre Handball',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Arve Giffre Handball',
      pathName: 'arvegiffrehandball',
      clubIds: ['2a2598ea-bb97-4f0d-a46b-9038e58d1ac4'],
      countclub: 1,
      whiteLabelId: '04710eec-076d-4f1c-849f-5a3f834c03e3',
      useMatch: true
    }
  },
  cotentindoor: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Cotent Indoor',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Cotent Indoor',
      pathName: 'cotentindoor',
      clubIds: ['754cdecf-071a-4f03-bb27-79d075194b5f'],
      countclub: 1,
      whiteLabelId: '20c9d7ab-a67c-4f53-857b-e48ad53af432',
      useMatch: true
    }
  },
  fitnesslac: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Fitness Lac',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Fitness Lac',
      pathName: 'fitnesslac',
      clubIds: ['14479cc4-2ff6-4fab-b054-e0504697870f'],
      countclub: 1,
      whiteLabelId: '29afa821-7671-4f38-800b-e37a5fbd3557',
      useMatch: true
    }
  },
  tsbjarville: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'TSB Jarville',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'TSB Jarville',
      pathName: 'tsbjarville',
      clubIds: ['02831da9-b093-4fcf-b7d6-8784c5cb9da8'],
      countclub: 1,
      whiteLabelId: 'd42c6b14-3f07-4fb9-90d8-a4f8e11a65e1',
      useMatch: true
    }
  },
  verticalitystudio: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Verticality Studio',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Verticality Studio',
      pathName: 'verticalitystudio',
      clubIds: ['5adaa9b8-a022-4f2c-a337-22dc34d1c27a'],
      countclub: 1,
      whiteLabelId: 'd88d5405-63a5-4fa6-88ac-e29deb119942',
      useMatch: true
    }
  },
  soccertime: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Soccer Time',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'mq',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Soccer Time',
      pathName: 'soccertime',
      clubIds: ['6a0ca113-df99-4fc8-a89d-80885362adb8'],
      countclub: 1,
      whiteLabelId: '74b0c8a0-ce1a-4f7e-8fb6-d103e7680623',
      useMatch: true
    }
  },
  sportsmersante: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Sports mer santé',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sports mer santé',
      pathName: 'sportsmersante',
      clubIds: ['f32e97e0-05af-4fdd-ba55-24f340d5b53a'],
      countclub: 1,
      whiteLabelId: '4e0f392c-d494-4f98-8026-81911eb6aaaf',
      useMatch: true
    }
  },
  tcseraing: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'ASBL Seraing Tennis Club',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'be',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'ASBL Seraing Tennis Club',
      pathName: 'tcseraing',
      clubIds: ['51c508da-2b04-4f72-a6b8-3e7817f95a8d'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  sportbreak: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Sport Break',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sport Break',
      pathName: 'sportbreak',
      clubIds: ['b79f8ea1-fd69-4fe6-8635-41e30ea457da'],
      countclub: 1,
      whiteLabelId: '505cda3e-3afc-4fcd-a803-dc5a4f709c14',
      useMatch: true
    }
  },
  padelherstal: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Padel Club Herstal',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Club Herstal',
      pathName: 'padelherstal',
      clubIds: ['b557aff1-ed0a-4f2a-8405-0fcdbfbf5021'],
      countclub: 1,
      whiteLabelId: '117e1fa3-c71a-4fc6-925d-61cbcb584c2b',
      useMatch: true
    }
  },
  tcwaremmien: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'ASBL Tennis Club Waremmien',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'ASBL Tennis Club Waremmien',
      pathName: 'tcwaremmien',
      clubIds: ['46d87e24-9dcf-4fed-adf2-624e6a40c83d'],
      countclub: 1,
      whiteLabelId: '9597aa2e-89fb-4f79-8564-2f59e96f9f09',
      useMatch: true
    }
  },
  clermont5: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Clermont Five',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Clermont Five',
      pathName: 'clermont5',
      clubIds: ['f7cce54d-d934-4f7b-ab77-fb4ecaf75d3d'],
      countclub: 1,
      whiteLabelId: '72563d6b-0f62-4f85-97fb-7ae6c739dfce',
      useMatch: true
    }
  },
  bigpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Big Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Big Padel',
      pathName: 'bigpadel',
      clubIds: ['adc3cc48-4163-4fe5-91fe-72ba71a400ee'],
      countclub: 1,
      whiteLabelId: 'd689789e-199a-4ffb-b040-c9969cb915e5',
      useMatch: true
    }
  },
  moissacnautique: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Moissac Nautique',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Moissac Nautique',
      pathName: 'moissacnautique',
      clubIds: ['8e310260-e2e6-4f73-9095-95c07d317f94'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  legarden: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Le Garden',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Garden',
      pathName: 'Legarden',
      clubIds: ['a126b4d4-a2ee-4f30-bee3-6596368368fb'],
      countclub: 1,
      whiteLabelId: 'c02ff9ae-b5e1-4fbd-807d-6f4796bd3cdc',
      useMatch: true
    }
  },
  breizhpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Breizh Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Breizh Padel',
      pathName: 'breizhpadel',
      clubIds: ['83abc3cd-22ee-4fbd-ac57-5f95b4971d9d'],
      countclub: 1,
      whiteLabelId: '128f36e2-8bf4-4faf-83ca-bfc0f4610dc1',
      useMatch: true
    }
  },
  arbonnoise: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Squash et Badminton - L\'Arbonnoise',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Squash et Badminton - L\'Arbonnoise',
      pathName: 'arbonnoise',
      clubIds: ['dbea85a3-7512-4f1f-bea3-d1202057696d'],
      countclub: 1,
      whiteLabelId: 'e5e7ecc5-794b-4fed-a62c-68b6bccf5c13',
      useMatch: true
    }
  },
  sportinparklaval: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Sport in Park Laval',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Sport in Park Laval',
      pathName: 'sportinparklaval',
      clubIds: ['1d7209ef-9848-4fcb-8de8-f2358b4464ac'],
      countclub: 1,
      whiteLabelId: '',
      useMatch: true
    }
  },
  padelhorizon: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Padel Horizon',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Horizon',
      pathName: 'padelhorizon',
      clubIds: ['efd176fa-cf39-4f7f-bf2d-5c7d9412148a'],
      countclub: 1,
      whiteLabelId: 'd6718774-e89c-4ff7-ae4c-3c23ca45c252',
      useMatch: true
    }
  },
  squashhorizon: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Padel Horizon',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Horizon',
      pathName: 'padelhorizon',
      clubIds: ['00274914-fced-4fef-ad05-c1af7db6184a'],
      countclub: 1,
      whiteLabelId: 'd6718774-e89c-4ff7-ae4c-3c23ca45c252',
      useMatch: true
    }
  },
  jeudepaume: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Jeu de paume et squash Paris',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Jeu de paume et squash Paris',
      pathName: 'jeudepaume',
      clubIds: ['452cb698-5356-4f33-9bac-fe35b1f3a288'],
      countclub: 1,
      whiteLabelId: '99e967d9-af88-4f59-bca0-2bf367c025a5',
      useMatch: true
    }
  },
  allinpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'All in Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'All in Padel',
      pathName: 'allinpadel',
      clubIds: ['41a84a31-1471-4fad-8344-37029d9749b5'],
      countclub: 1,
      whiteLabelId: 'caa68b84-cba1-4f49-acd0-008026520167',
      useMatch: true
    }
  },
  moovingarena: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Mooving Arena',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Mooving Arena',
      pathName: 'moovingarena',
      clubIds: ['0072bd69-98c9-4f6e-921d-3497c4f0599b'],
      countclub: 1,
      whiteLabelId: '4cc403a1-9554-4f85-82f0-8f91ee923f82',
      useMatch: true
    }
  },
  hangar21: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Hangar 21',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Hangar 21',
      pathName: 'hangar21',
      clubIds: ['9a638fba-4426-4f88-86e2-c7463a09a214'],
      countclub: 1,
      whiteLabelId: 'abc07f0f-1be2-4f62-96ee-3c7e8d6b4820',
      useMatch: true
    }
  },
  b14: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'B14',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'B14',
      pathName: 'b14',
      clubIds: ['f73b2bef-eedd-4fba-9d72-15595ac723fa'],
      countclub: 1,
      whiteLabelId: '2c2076fe-96f8-4f14-b3ac-46cb4c32281f',
      useMatch: true
    }
  },
  loirevalleepadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Loire Vallée Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Loire Vallée Padel',
      pathName: 'loirevalleepadel',
      clubIds: ['6facc2a9-5703-4f10-9456-600826b0aa1e'],
      countclub: 1,
      whiteLabelId: '1b76a020-f68f-4fd1-b68d-28cb97aa874e',
      useMatch: true
    }
  },
  espritpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Esprit Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Esprit Padel',
      pathName: 'espritpadel',
      clubIds: ['4c9477d2-574e-4f61-bc26-f30c1f46265d'],
      countclub: 1,
      whiteLabelId: '677e6bb5-3a44-4f62-bcfd-c335d662378b',
      useMatch: true
    }
  },
  padelarena: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Padel Arena',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Padel Arena',
      pathName: 'padelarena',
      clubIds: ['f8d2dafd-dad8-4fff-9977-af7046e2eadd'],
      countclub: 1,
      whiteLabelId: '7305445b-f08b-4f59-9462-80dbfeaca3aa',
      useMatch: true
    }
  },
  lekomplex: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Le Komplex',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Le Komplex',
      pathName: 'lekomplex',
      clubIds: ['33ec6679-ff8b-4f3c-874a-c568a998e013'],
      countclub: 1,
      whiteLabelId: '8291cebe-caf3-4fbe-8d88-08ce1720c48e',
      useMatch: true
    }
  },
  letpadel: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Let Padel',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Let Padel',
      pathName: 'letpadel',
      clubIds: ['6a99f5f6-32bd-4fe3-ba15-8d22b1f58c1f'],
      countclub: 1,
      whiteLabelId: '9547ffa3-fb4e-4f84-944f-b73d64c05934',
      useMatch: true
    }
  },
  olympiasports: {
    production: true,
    developpement: false,
    domainAPI: 'https://api-v3.doinsport.club',
    pathFiles: 'https://api-v3.doinsport.club',
    oneSignalAppId: '04171018-4ccb-4d21-95d6-2784fafcac97', // '4802b94b-0b89-4bc1-af74-35150a6cc175',
    oneSignalGgl: '316548473273', // '370335984586',
    branchIOKey: 'key_live_dar1VK6CsbjZP0iDEydT0jneFxj2gGzL',
    nameApp: 'Olympia Sports',
    bookingOrigin: 'web_site',
    tabbarCountItems: 4,
    versionApp: '3.0.0',
    stripePK: 'pk_live_ASS1268VW0gTF0hTkEs9Cno1003DMFnhdw',
    defaultLang: 'fr',
    defaultPhoneLang: 'fr',
    markerIcon: 'https://api.doinsport.club/assets/v2/img/pin_doin.png',
    paymentsProvider: ['stripe'],
    useMb: true,
    marqueBlanche: {
      nameMb: 'Olympia Sports',
      pathName: 'olympiasport',
      clubIds: ['0fef95bd-efe1-4f81-9cf1-d5948d2d5dea'],
      countclub: 1,
      whiteLabelId: '3e5d5d7a-b9af-4fb4-8f9a-95d671b0a027',
      useMatch: true
    }
  }
};

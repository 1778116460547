import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../shared/models/user';
import { AlertController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { PhotoService } from 'src/app/shared/services/photo.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-new-user-form',
  templateUrl: './new-user-form.component.html',
  styleUrls: ['./new-user-form.component.scss'],
  providers: []
})
export class NewUserFormComponent implements OnInit {
  @Input() serverSideErrors$: Observable<any>;
  @Input() phoneNumber: string;
  @Output() confirmForm = new EventEmitter();

  form: FormGroup;
  PHOTO_STORAGE = 'avatar';
  imageAvatar: any;
  avatarTmp: any;
  error = false;
  errors: any;
  maxDate: string;

  requiredField = this.translateService.instant('required_field');
  submitAttempt = false;

  validationMessages = {
      firstName: [
          {type: 'required', message: this.requiredField}
      ],
      lastName: [
          {type: 'required', message: this.requiredField}
      ],
      email: [
          {type: 'required', message: this.requiredField}
      ],
      password: [
        {type: 'required', message: this.requiredField},
        {type: 'server', message: this.requiredField},
        {type: 'minlength', message: this.translateService.instant('password_min_characters')}
      ]
  };

  constructor(
    public alertController: AlertController,
    public domSanitizer: DomSanitizer,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    // this.initErrors();
    this.imageAvatar = 'assets/images/avatar.png';
    this.maxDate = moment(new Date()).subtract(10, 'years').format().toString();

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email]], // Validators.required,
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnInit(): void {
    this.serverSideErrors$.pipe(
      filter(errors => errors !== null),
      tap( errors => this.handleFormError(this.form, errors))
    )
    .subscribe();

    this.photoService.avatarAsBlob$
      .subscribe( avatarObject => {
        if (avatarObject !== null) {
          const objectURL = 'data:image/jpeg;base64,' + avatarObject.base64String;
          this.imageAvatar = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        } else {
          this.imageAvatar = 'assets/images/avatar.png';
        }
      });
  }

  handleFormError(form: FormGroup, errors){
    const violations = typeof errors['error'] !== 'undefined' ?  errors['error']['violations'] || null : null;
    if (violations && violations.constructor === Array){
      // tslint:disable-next-line: forin
      for (const key in violations){
        const item = violations[key];
        if (form.contains(item['propertyPath'])){
          form.controls[item['propertyPath']].setErrors({
            serverSide: item['message']
        });
        }
      }
    } else {
        // this.handleError(errors);
    }
  }

  sanitize(objectURL) {
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  addAvatar() {
    this.photoService.changeAvatar('signUp');
  }

  onSubmit() {
    this.submitAttempt = true;
    if (this.form.valid) {
      const user: User = {
        id: null,
        email: this.form.get("email").value !== "" ? this.form.get("email").value : null,
        firstName: this.form.get("firstName").value,
        lastName: this.form.get("lastName").value,
        phoneNumber: this.phoneNumber,
        plainPassword: this.form.get("password").value,
      };
      this.confirmForm.emit(user);
    }
  }

}

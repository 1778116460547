import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClubDistanceComponent } from './club-distance.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ClubDistanceComponent],
  exports: [ClubDistanceComponent]
})
export class ClubDistanceModule { }

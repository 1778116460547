import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orange-money',
  templateUrl: './orange-money.component.html',
  styleUrls: ['./orange-money.component.scss']
})
export class OrangeMoneyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

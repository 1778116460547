import { AfterViewInit, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ClubState } from 'src/app/club/store/club.reducers';
import {AppState} from '../../state/app.state';
import {AuthService} from '../../shared/services/user/auth.service';

@Component({
  selector: 'app-like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss']
})
export class LikeButtonComponent implements OnInit, AfterViewInit {
  isApreferredClub = false;

  constructor(
    private userStore: Store<AppState>,
    private authService: AuthService,
    private clubStore: Store<ClubState>
  ) { }

  ngOnInit(): void {
    // TODO implement the logic with the store
    // Compare current club id and the list of preferred clubs
    // implement action on button clicked

  }

  ngAfterViewInit() {
      this.authService.getConnectedUser()
        .pipe(
          switchMap( (resp: boolean) => {
            return forkJoin([
                this.clubStore.select('clubIsLoading')
            ]);
          }),
          map(res => res),
        )
        .subscribe( () => {});
  }

  likeItOrNot() {
    this.isApreferredClub = !this.isApreferredClub;
  }


}

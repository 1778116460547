import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigService } from '../../../config/config.service';
import { AuthService } from '../../../shared/services/user/auth.service';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserTokenService } from '../../../shared/services/storage/user-token.service';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state/app.state';
import { UserService } from '../../../shared/services/storage/user.service';
import { User } from '../../../shared/models/user';
import { HttpService } from '../../../shared/services/http.service';
import { SignUpComponent } from '../sign-up/sign-up.component';
import intlTelInput from 'intl-tel-input';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { of } from 'rxjs';
import { OneSignalServiceService } from 'src/app/shared/services/oneSignal/one-signal-service.service';
import { getSecondaryColor } from 'src/utils/getSecondaryColor';
import { ToastService } from 'src/app/shared/services/toast.service';

import {EnvironmentService} from "../../../shared/services/environment/environment.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, AfterViewInit {
  phoneNumber: string;
  separateDialCode: boolean;
  SearchCountryField: any;
  TooltipLabel: any;
  CountryISO: any;
  preferredCountries: Array<any>;
  phoneForm: any;
  logoUrl: string;
  errorPhone: boolean;
  errorPhoneMessage: string;
  showPasswordInput: boolean;
  password: string;
  stepLogin: number;
  showPhoneCode: boolean;
  phoneCode: number;
  showPhoneInput: boolean;
  showUpdatePassword: boolean;
  showConfirmResetPassword: boolean;
  showResetPassword: boolean;
  token: string;
  iti: any;
  isFocus: boolean;
  env;
  showPhoneCodeText: boolean;
  secondaryColorIsWhite: boolean;

  passwordImage = '';
  welcome = '';
  forgotImage = '';

  constructor(
      private config: ConfigService,
      private authService: AuthService,
      private userToken: UserTokenService,
      private ctl: NavController,
      private userStorage: UserService,
      private store: Store<AppState>,
      private modalCtr: ModalController,
      private httpService: HttpService,
      public translate: TranslateService,
      public loaderService: LoaderService,
      private oneSignalService: OneSignalServiceService,
      private toastService: ToastService,
      private environmentService: EnvironmentService,
      private router: Router,
  ) {
    this.env = this.environmentService.getEnvFile();
    this.isFocus = false;
    this.showBlock('input_phone');
    this.logoUrl = this.config.getLogoUrl();
    this.separateDialCode = false;

    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required])
    });
    this.errorPhone = false;
    this.errorPhoneMessage = '';
    this.welcome = 'assets/images/illustrations' + (this.env.useMb ? ('_' + this.env.marqueBlanche.pathName) : '') + '/welcome.svg';

    this.passwordImage = 'assets/images/illustrations' + (this.env.useMb ? ('_' + this.env.marqueBlanche.pathName) : '')  + '/create_password.svg';
    this.forgotImage = 'assets/images/illustrations' + (this.env.useMb ? ('_' + this.env.marqueBlanche.pathName) : '')  + '/forgot_password.svg';
  }

  ngOnInit(): void {
    const secondaryColor = getSecondaryColor();
    if (secondaryColor && secondaryColor === 'ffffff') {
      this.secondaryColorIsWhite = true;
    } else {
      this.secondaryColorIsWhite = false;
    }
  }

  ngAfterViewInit() {
    const input = document.querySelector("#phone");
    this.iti = intlTelInput(input, {
      customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        if (selectedCountryData.dialCode === '262') {
          return "Réunion (+262)"
        } else {
          return selectedCountryPlaceholder;
        }
      },
      // any initialisation options go here
      utilsScript: "src/utils/utils.js",
      initialCountry: this.env.defaultPhoneLang,
      onlyCountries: ['ae', 'be', 'ch', 'ci', 'de', 'es', 'fr', 'gb', 'gp', 'it', 'lu', 'mq', 'nl', 'pt', 're', 'sn', 'mu', 'gf', 'th', 'se', 'mc', 'ma', 'nc', 'mg', 'dz']
    });
  }

  phoneChange(event) {
    this.phoneNumber = event.target.value;
    this.errorPhoneMessage = '';
    this.errorPhone = false;
  }

  hasErrorPhoneNumber(event) {
  }

  passwordChange() {
    this.errorPhoneMessage = '';
    this.errorPhone = false;
  }
  countryChange(event) {
    this.errorPhoneMessage = '';
    this.errorPhone = false;
  }

  updateUrlWelcome(error) {
    this.welcome = 'assets/images/logos/icon_doin.png';
  }

  updateUrlPassword(error) {
    this.passwordImage = 'assets/images/logos/icon_doin.png';
  }

  updateUrlForgot(error) {
    this.forgotImage = 'assets/images/logos/icon_doin.png';
  }


  async confirmPhone() {
    this.phoneNumber = this.iti.getNumber();
    if (this.iti.getValidationError() > 0) {
      this.errorPhoneMessage = this.translate.instant('user_phone_not_valid');
      this.errorPhone = true;
    } else {
      await this.loaderService.presentLoading();
      this.errorPhoneMessage = '';
      this.errorPhone = false;
      this.authService.checkIfUserExists(this.phoneNumber).pipe(tap(resp => {
        if (resp.exist) {
          // this.loaderService.dismiss();
          this.showBlock(resp.type);
        } else {
          this.errorPhoneMessage = this.translate.instant('user_not_found');
          this.errorPhone = true;
        }
        this.loaderService.dismiss();
      }), catchError(err => {
        this.loaderService.dismiss();
        return err;
      })).toPromise().then(() => {
        this.loaderService.dismiss();
      }).catch(err => {
        this.loaderService.dismiss();
      });
    }
  }

  confirmPassword() {
    this.loaderService.presentLoading();
    this.errorPhone = false;
    this.authService.signInUser(this.phoneNumber, this.password)
      .pipe(
        tap( tokens => {
          // TODO : There add OneSignalService.AddOneSignal
          if (tokens && !this.errorPhone) {
            this.userToken.add(tokens).then(() => {
              this.authService.getConnectedUser()
                .subscribe(resp => {
                  // this.oneSignalService.addOnesignalIdUser();
                  this.loaderService.dismiss();
                  this.close();
                  const currentUrl = this.router.url;
                  this.router.navigate(['/account/me'], {skipLocationChange: true}).then(() => {
                    this.router.navigateByUrl(`${currentUrl}`);
                  });
                });
            }).catch(error => {
              this.loaderService.dismiss();
            });
          } else {
            this.toastService.presentError('bad_password', 'bottom');
            this.loaderService.dismiss();
            this.errorPhone = true;
          }
        }),
        catchError((err: any) => {
          this.loaderService.dismiss();
          this.close();
          this.errorPhone = true;
          this.errorPhoneMessage = this.translate.instant('bad_password');
          return of(err);
        })
      )
      .subscribe( () => {
        setTimeout( _ => {
          this.oneSignalService.addOnesignalIdUser();
        }, 2000);
        this.loaderService.dismiss();
      });
  }

  confirmResetPassword() {
    this.showBlock('confirm_reset_password');
  }

  sendCode() {
    this.resetPassword();
    this.showBlock('send_code_to_reset_password');
  }

  resetPassword() {
    // this.loaderService.presentLoading();
    this.phoneNumber = this.iti.getNumber();
    if (this.iti.getValidationError() > 0) {
      this.loaderService.dismiss();
      this.errorPhoneMessage = this.translate.instant('user_phone_not_valid');
      this.errorPhone = true;
    } else {
      this.errorPhoneMessage = '';
      this.errorPhone = false;
      this.authService.getPhoneNumberValidationCode(this.phoneNumber)
        .pipe(
          tap(resp => {
            // this.showBlock('v2');
            this.loaderService.dismiss();
          }),
          catchError(err => {
            this.loaderService.dismiss();
            return err;
          })
        )
        .toPromise()
          .then(() => {
            this.loaderService.dismiss();
          })
          .catch(err => {
          this.loaderService.dismiss();
        });
    }

    /* this.errorPhone = false;
    this.errorPhoneMessage = '';
    if (this.phoneNumber !== '') {
      this.authService.checkIfUserExists(this.phoneNumber)
          .pipe(tap(resp => {
            if (resp.exist) {
              this.showBlock('v2');
            } else {
              this.errorPhoneMessage = this.translate.instant('user_not_found');
              this.errorPhone = true;
            }
            this.loaderService.dismiss();
          }), catchError(err => {
            this.loaderService.dismiss();
            return err;
          })).toPromise().then(() => {
            this.loaderService.dismiss();
          }).catch(err => {
            this.loaderService.dismiss();
          });

    } else {
      this.errorPhone = true;
      this.errorPhoneMessage = this.translate.instant('type_your_phone');
    } */
  }

  showBlock(version) {
    if  (version === 'v2') {
      this.showPasswordInput = false;
      this.showUpdatePassword = true;
      this.showPhoneInput = false;
      this.showPhoneCode = true;
      this.showConfirmResetPassword = false;
      this.showResetPassword = false;
      this.stepLogin = 1;
    } else if (version === 'new_password') {
      this.showPasswordInput = false;
      this.showUpdatePassword = true;
      this.showPhoneInput = false;
      this.showPhoneCode = false;
      this.showConfirmResetPassword = false;
      this.showResetPassword = false;
      this.stepLogin = 2;
    } else if (version === 'v3') {
      this.showPasswordInput = true;
      this.showUpdatePassword = false;
      this.showPhoneInput = false;
      this.showPhoneCode = false;
      this.showConfirmResetPassword = false;
      this.showResetPassword = false;
      this.stepLogin = 2;
    } else if (version === 'input_phone') {
      this.showPasswordInput = false;
      this.showUpdatePassword = false;
      this.showPhoneInput = true;
      this.showPhoneCode = false;
      this.showResetPassword = false;
      this.stepLogin = 1;
    } else if (version === 'confirm_reset_password') {
      this.showPasswordInput = false;
      this.showUpdatePassword = false;
      this.showPhoneInput = false;
      this.showPhoneCode = false;
      this.showConfirmResetPassword = true;
      this.showResetPassword = false;
      this.stepLogin = 1;
    } else if (version === 'send_code_to_reset_password') {
      this.showPasswordInput = false;
      this.showUpdatePassword = false;
      this.showPhoneInput = false;
      this.showPhoneCode = true;
      this.showPhoneCodeText = true;
      this.showConfirmResetPassword = false;
      this.showResetPassword = false;
      this.stepLogin = 1;
    } else if (version === 'reset_password') {
      this.showPasswordInput = false;
      this.showUpdatePassword = false;
      this.showPhoneInput = false;
      this.showPhoneCode = false;
      this.showPhoneCodeText = false;
      this.showConfirmResetPassword = false;
      this.showResetPassword = true;
      this.stepLogin = 1;
    }
  }

  confirmPhoneCode(code) {
    this.loaderService.presentLoading().then();
    this.phoneCode = code;
    this.authService.verifyPhoneNumberValidationCode(this.phoneNumber, this.phoneCode).subscribe(data => {
      if (data) {
        this.userToken.add(data).then();
        this.token = data.token;
        this.showBlock('new_password');
        this.loaderService.dismiss();
      }
    });
  }

  close() {
    this.modalCtr.dismiss().then(() => {});
  }

  confirmUpdatePassword(password) {
    // this.loaderService.presentLoading();
    this.password = password;
    const userId = this.httpService.extractUserIdFromToken(this.token);
    const user: User = {
      id: userId,
      plainPassword: password
    };
    this.authService.updatePasswordUser(userId, user, this.token)
      .subscribe(resp => {
        // this.loaderService.dismiss();
        this.toastService.presentSuccess('new_password_update_success');
        this.confirmPassword();
      });
  }

  async goToSignUp() {
    this.modalCtr.dismiss().then();
    const modal = await this.modalCtr.create({
      component: SignUpComponent,
      cssClass: 'sign-class',
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios',
      // presentingElement: await this.modalCtr.getTop()
    });
    modal.present().then(() => {});
  }

}

import * as GeolocationActions from '../actions/geolocation.actions';
import {Geolocation} from '../../shared/models/geolocation';

export function geolocationReducer(state: Geolocation = {longitude: null, latitude: null}, action: GeolocationActions.Actions): any {
    switch (action.type) {
        case GeolocationActions.ADD_GEOLOCATION:
            return action.payload;
        case GeolocationActions.REMOVE_GEOLOCATION:
            return {longitude: null, latitude: null};
        default:
            return state;
    }
}

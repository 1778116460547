import {Component, OnInit, Input, ViewChild, AfterViewInit} from '@angular/core';
import {IonInfiniteScroll, ModalController, ViewWillEnter} from '@ionic/angular';
import { AccountService } from '../../../shared/services/account/account.service';
import { filter, map, tap } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { RefillComponent } from '../refill/refill.component';
import {select, Store} from "@ngrx/store";
import {getClubCurrency, getCurrentClubId} from "../../../club/store";
import {ClubState} from "../../../club/store/club.reducers";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-my-wallets-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  wallet: any;
  walletDetails: any;
  clubCurrencySub: Subscription;
  clubCurrency: any;
  lastTransactions: any[] = [];
  pathUrl: string;
  showHistory = false;
  nextPage = null;
  env;
  showSkeleton = true;

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(
      private modalCtrl: ModalController,
      private environmentService: EnvironmentService,
      private accountService: AccountService,
      private clubStore: Store<ClubState>,
  ) {
      this.clubCurrencySub = this.clubStore.pipe(
          select(getClubCurrency),
          tap(currency => this.clubCurrency = currency)
      ).subscribe();
    this.env = this.environmentService.getEnvFile();
    this.pathUrl = environmentService.getEnvFile().pathFiles;
  }

  ngOnInit(): void {
    this.accountService.getWallets()
        .pipe(
            filter( data => data !== undefined),
            map( dataMap => dataMap['hydra:member']),
            tap( wallets => {
              if (this.environmentService.getEnvFile().useMb) {
                this.clubStore.pipe(
                    select(getCurrentClubId),
                    tap(clubId => {
                      const matchingWallets = wallets.filter( wallet => wallet.club['@id'] === '/clubs/' + clubId);
                      this.wallet = matchingWallets[0];
                    })
                ).subscribe();
              }
            }),
            tap(wallet => {
              this.accountService.getWalletDetails(this.wallet.client.split('/')[3]).pipe(
                  tap(walletDetail => {
                    this.walletDetails = walletDetail['hydra:member'];
                    if (walletDetail['hydra:view']['hydra:next']) {
                        this.nextPage = walletDetail['hydra:view']['hydra:next'];
                    }
                    for (let i = 0; i < 3; i++) {
                        this.lastTransactions.push(this.walletDetails[i])
                    }
                    this.showSkeleton = false;
                  })
              ).subscribe();
            })
        )
        .subscribe();
  }

  close() {
      if (this.showHistory === true) {
          this.showHistory = false;
      } else {
          this.modalCtrl.dismiss({refresh: true});
      }
  }

  goToRefill() {
    this.modalCtrl.create({
      component: RefillComponent,
      componentProps: {
        wallet: this.wallet
      }
    })
    .then(modal => {
      modal.present().then();
      modal.onDidDismiss().then( async data => {
        if (data.data.amountRefresh) {
          this.wallet.balance = this.wallet.balance + data.data.amountAdded * 100;
        }
      });
    });
  }

  showHistoricWallet() {
      this.showHistory = true;
  }

  loadMoreData() {
      this.accountService.getWalletDetails(null, this.nextPage).pipe(
          tap(walletDetail => {
              this.nextPage = null;
              walletDetail['hydra:member'].forEach(wDet => {
                  this.walletDetails.push(wDet);
              });
              if (walletDetail['hydra:view']['hydra:next']) {
                  this.nextPage = walletDetail['hydra:view']['hydra:next'];
              }
              this.infiniteScroll.complete();
              if (!this.nextPage) {
                  this.infiniteScroll.disabled = true;
              }

          })
      ).subscribe();
  }
}

import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Platform, ViewDidEnter, ToastController, ModalController, ViewWillEnter} from '@ionic/angular';
import {AuthService} from '../shared/services/user/auth.service';
import {UserService} from '../shared/services/storage/user.service';
import {User} from '../shared/models/user';
import {concatMap, filter, map, tap} from 'rxjs/operators';
import {BehaviorSubject, from, Observable, of, Subject} from 'rxjs';
import {ClubService} from '../club/club.service';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {EnvironmentService} from '../shared/services/environment/environment.service';
import {ClubIdStorageService} from '../shared/services/clud-id-storage/club-id-storage.service';
import {MySubscriptionsComponent} from '../account/my-subscriptions/my-subscriptions.component';
import {MyCreditsComponent} from '../account/my-credits/my-credits.component';
import {MyWalletsComponent} from '../account/my-wallets/my-wallets.component';
import {BookingService} from '../shared/services/booking/booking.service';
import {Period} from 'src/app/shared/enums/period';
import {MatchService} from '../matches/match.service';
import {select, Store} from "@ngrx/store";
import {ClubState} from "../club/store/club.reducers";
import {getCurrentClub, getCurrentClubId} from "../club/store";
import {ChoiceClubComponent} from "../modal/choice-club/choice-club.component";
import {MyQrCodeComponent} from "./components/my-qr-code/my-qr-code.component";
import {AccountService} from "../shared/services/account/account.service";
import {DetailsComponent} from "../account/my-wallets/details/details.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements ViewDidEnter, OnDestroy {
  user: User;
  refreshSub$ = new BehaviorSubject<boolean>(false);
  refresh$ = this.refreshSub$.asObservable();
  refreshMatchesSub$ = new BehaviorSubject<boolean>(false);
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  refreshMatches$ = this.refreshMatchesSub$.asObservable();
  clubs: Array<any> = [];
  environnement;
  path = this.environmentService.getEnvFile().pathFiles;
  clubSelected: any = null;
  clubId: any;
  nextBookings: any;
  Period = Period;
  bookings: any;
  pastBookings: any;
  skeletonBookings = false;
  userMe: any;

  titleMatch: any;
  matchs: any;
  noChange = false;

  constructor(
      public translate: TranslateService,
      public authService: AuthService,
      private userService: UserService,
      private clubService: ClubService,
      private platform: Platform,
      private bookingService: BookingService,
      private viewer: PhotoViewer,
      private environmentService: EnvironmentService,
      public clubIdStorageService: ClubIdStorageService,
      public toastController: ToastController,
      private modalController: ModalController,
      private matchService: MatchService,
      private clubStore: Store<ClubState>,
      private accountService: AccountService
  ) {
    this.environnement = this.environmentService.getEnvFile();
  }

  async load() {
    try {
      this.skeletonBookings = true;
      this.clubs = [];
      this.clubId = await this.clubIdStorageService.getClubId().then(clubId =>  clubId);
      this.userMe = await this.authService.getConnectedUser(this.clubId).toPromise();
      if (this.environnement.useMb) {
        await this.clubStore.pipe(select(getCurrentClub), tap(club => {
          if (club && club.id) {
            this.clubSelected = club;
          }
        })).subscribe();
      }

      this.matchs = [];
      if (this.userMe === null || this.userMe === undefined) {
        await this.matchService.getMatches({
          clubIds: this.environmentService.getEnvFile().marqueBlanche.clubIds
        }, false, this.noChange ? null : this.clubId).subscribe(
            (matchs) => {
              if (matchs !== undefined && matchs['hydra:member'].length) {
                this.titleMatch = this.translate.instant('home_page_matches_wl');
                this.matchs = matchs['hydra:member'];
              }
            }
        );
        this.skeletonBookings = false;
      } else {
        await this.matchService.getMyMatches(this.userMe['id'], false, Period.NEXT, null, this.noChange ? null :this.clubId).subscribe(
            async (matchs) => {
              if(matchs['hydra:member'].length > 0) {
                this.titleMatch = (
                    this.environmentService.overwriteMatchEvent() ?
                        this.translate.instant('my_upcoming_stages') :
                        this.translate.instant('my_upcoming_matches')
                );
                this.matchs = matchs['hydra:member'];
              }
              if (matchs['hydra:member'].length === 0) {
                await this.matchService.getMatches({
                  clubIds: this.environmentService.getEnvFile().marqueBlanche.clubIds
                }, false, this.noChange ? null : this.clubId)
                    .subscribe(
                        (matchesSec) => {
                          if (matchesSec['hydra:member'].length > 0) {
                            this.matchs = matchesSec['hydra:member'];
                            this.titleMatch = this.translate.instant('home_page_matches_wl');

                          }
                        }
                    );
              }
            }
        )

        this.nextBookings = [];
        this.pastBookings = [];
        this.bookings = [];

        const stream1 = await this.bookingService.getMyBookings(false, Period.NEXT, 5, this.clubId, this.userMe['id']).toPromise();
        const stream2 = await this.bookingService.getMyBookings(false, Period.PAST, 5, this.clubId, this.userMe['id']).toPromise();

        if (stream1) {
          this.nextBookings = stream1['hydra:member'];
        }

        if (stream2) {
          this.pastBookings = stream2['hydra:member'];
        }

        if (this.nextBookings !== undefined && this.nextBookings.length) {
          this.bookings = [...this.nextBookings];
        } else if (this.pastBookings !== undefined && this.pastBookings.length && this.nextBookings.length === 0) {
          this.bookings = [...this.pastBookings];
        }

        this.bookings.sort((a, b) => {
          return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
        });

        this.skeletonBookings = false;
      }

    } catch (e) {
      console.log(e);
    }
  }

  async ionViewDidEnter() {
    this.load();
  }

  async refreshClubs(event) {
    if (event.reload === true) {
      this.clubSelected = event.clubSelected;
      this.load();
    }
  }

  updateMatches(event) {
    if (event === true) {
      this.refreshMatchesSub$.next(true);
    }
  }

  viewPic(picUrl) {
    this.platform.ready().then( () => {
      this.viewer.show(picUrl);
    });
  }

  goTo(component) {
    let componentToOpen;
    switch (component) {
      case "MySubscriptionsComponent":
        componentToOpen = MySubscriptionsComponent;
        break;
      case "MyCreditsComponent":
        componentToOpen = MyCreditsComponent;
        break;
      case "MyWalletsComponent":
        componentToOpen = DetailsComponent;
        break;
    }

    this.modalController.create({
      component: componentToOpen
    })
        .then(modal => {
          modal.present().then();
          modal.onDidDismiss().then( data => {
          });
        });
  }

  openQrCode() {
    this.modalController.create({
      component: MyQrCodeComponent,
      cssClass: 'modal-qr-code',
      backdropDismiss: true,
      componentProps: {
        userMe: this.userMe,
        clubId: this.clubId
      }
    }).then(modal => {
      modal.present().then();
      modal.onDidDismiss().then(data => {
      });
    });
  }

  showQrCode() {
      if (['soccertimeguyane'].includes(this.environnement.marqueBlanche.pathName)) {
          return true;
      }
    if (this.clubSelected?.accessControlService?.checkinTypes) {
      return this.clubSelected.accessControlService?.checkinTypes?.findIndex(el => el === 'qrcode') !== -1;
    }
  }

  hasAccessCode() {
    if (this.userMe) {
      if (this.userMe.clients) {
        if (this.userMe.clients.length > 0) {
          return this.userMe.clients[0].accessCode !== "";
        }
      }
    }

    return false;
  }
  ngOnDestroy() {
    this.refreshSub$.next(false);
    this.resetFormSubject.next(true);
  }

}

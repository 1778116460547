import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Club} from '../../../shared/models/club';

import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  @Input() club: Club;
  @ViewChild('imgMap') imgMap: ElementRef;

  clubName: string;
  clubLocation: { lat: number; lng: number; };
  API_KEY: string;

  constructor(
    private environmentService: EnvironmentService) {}

  ngOnInit() {
    this.API_KEY = 'AIzaSyCMab6pjh9wu3jfu_GeHd3VXZbhafvvX7M';
  }

  ngAfterViewInit() {
    this.loadMap();
  }

  openClubMap() {
    // TODO : Open map
  }

  loadMap() {
    let staticMapUrl = 'https://maps.googleapis.com/maps/api/staticmap';
    const mapOptions = {
      center: {lat: this.club.latitude, lng: this.club.longitude},
      zoom: 18,
      mapType: 'roadmap'
    };
    const marker = {
      title: '',
      lat: null,
      lng: null,
    };
    let markerIcon: string;
    marker.title = this.club.name;
    marker.lat = this.club.latitude;
    marker.lng = this.club.longitude;
    markerIcon = this.environmentService.getEnvFile().markerIcon;
    staticMapUrl += '?center=' + mapOptions.center.lat + ',' + mapOptions.center.lng;
    staticMapUrl += '&size=800x800';
    staticMapUrl += '&zoom=' + mapOptions.zoom;
    staticMapUrl += '&maptype=' + mapOptions.mapType;
    staticMapUrl += '&markers=icon:' + markerIcon + '|' + marker.lat + ',' + marker.lng;
    staticMapUrl += '&key=' + this.API_KEY;
    this.imgMap.nativeElement.src = staticMapUrl;
    this.imgMap.nativeElement.style.display = 'block';
  }

}

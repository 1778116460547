import { Component, Input, OnInit } from '@angular/core';
import { StripeService } from '../../../shared/services/payment/stripe.service';
import { ToastService } from '../../../shared/services/toast.service';
import { ModalController } from '@ionic/angular';
import { PaymentCard } from 'src/app/shared/models/payment-card';
import { PaymentCardService } from 'src/app/shared/services/storage/payment-card.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() cards: Array<PaymentCard> = [];
  showAllCards: boolean;

  constructor(
    private paymentCardService: PaymentCardService,
    private stripeService: StripeService,
    private toastService: ToastService,
    private modalCtlr: ModalController
  ) {
    this.stripeService.reloadStripeAccount();
    // this.cards = [];
    this.showAllCards = true;
    // this.getAllCards();
  }

  ngOnInit(): void {
  }

  showAll() {
    this.showAllCards = true;
  }

  defineDefault(index) {
    /* if (this.showAllCards) {
      this.showAllCards = false;
      this.pmService.updateDefined(index).then(data => {
        this.cards = data;
      });
    } */
    this.paymentCardService.updateDefined(index).then(data => {
      this.cards = data;
      this.modalCtlr.dismiss({selectedCard: this.cards[index]});
    });
  }

  addCard(name: HTMLInputElement, numberCard: HTMLInputElement, month: HTMLInputElement, year: HTMLInputElement, cvc: HTMLInputElement) {
    if (name.value === '' ||
        numberCard.value === '' ||
        month.value === '' ||
        year.value === '' ||
        cvc.value === ''
    ) {
      this.toastService.presentError('form_credit_card_invalid', 'top');
      return false;
    }
    this.stripeService.validateCardNumber(numberCard.value).then(res => {
      // TODO : validate card
    });

    const type = this.paymentCardService.checkType(numberCard.value);
    this.paymentCardService.addPaymentCard({
      name: name.value,
      cvc: cvc.value,
      default: true,
      expMonth: parseInt(month.value, null),
      number: numberCard.value,
      type: '' + type,
      expYear: parseInt(year.value, null)
    }).then(data => {
      this.cards = data;
    });
  }

  back() {
    this.modalCtlr.dismiss({}).then(() => {});
  }

}

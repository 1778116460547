import {
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild } from '@angular/core';
import { IonInfiniteScroll, ViewDidEnter, ViewWillEnter, ViewDidLeave, ViewWillLeave } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {last, map, tap} from 'rxjs/operators';
import { ClubMatch } from '../match.model';
import { MatchActions } from '../store';
import { MatchState } from '../store/match.reducer';
import {
  getAllMatches,
  getHydraView,
  getMatchesLoadedState,
  getMatchesLoadingError,
  getMatchesLoadingState } from '../store/match.selectors';
import { Geolocation } from 'src/app/shared/models/geolocation';
import { MatchCardConfig } from 'src/app/shared/enums/match-card-config';
import { TranslateService } from '@ngx-translate/core';
import {EnvironmentService} from "../../shared/services/environment/environment.service";
import {getCurrentClub, getCurrentClubId} from "../../club/store";
import {ClubState} from "../../club/store/club.reducers";

@Component({
  selector: 'app-match-list',
  templateUrl: './match-list.component.html',
  styleUrls: ['./match-list.component.scss']
})
export class MatchListComponent implements OnInit, OnDestroy {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Input() isLoadingMoreData = false;
  @Input() userId: string;
  @Input() userLocation$: Observable<Geolocation>;
  @Output() loadNext = new EventEmitter();
  @Output() reloadMatches = new EventEmitter();

  env;
  MatchCardConfig = MatchCardConfig;
  matches: ClubMatch[] = [];
  matches$: Observable<ClubMatch[]>;
  matchesAreLoaded = false;
  matchesAreLoading = false;
  matchesLoadingError: string;
  hasNext: boolean;
  nextPage: string;
  isLoaded = false;
  allSubscriptions$ = new Subscription();
  once = 0;

  constructor(
    private matchstore: Store<MatchState>,
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
    private clubStore: Store<ClubState>
  ) {
    this.env = this.environmentService.getEnvFile();
  }

  ngOnInit() {
    this.matches$ = this.matchstore.pipe(select(getAllMatches))
      .pipe(
        tap( response => {
          if (this.infiniteScroll) {
            this.infiniteScroll.complete();
            this.infiniteScroll.disabled = false;
          }
        }),
        map( matches => {
          return matches.filter( m => {
            return (m.access !== 'for_guest') || (m.access === 'for_guest' && m.userClient.id === this.userId) === true;
          });
        }),
        tap( matches => {
          if (matches.length < 1) {
            // TODO Match: display message not any match
          }
        })
      );

    this.allSubscriptions$.add(this.matchstore.pipe(select(getMatchesLoadedState))
      .pipe(
        tap( isLoaded => {
          this.matchesAreLoaded = isLoaded;
        })
      )
      .subscribe()
    );

    this.allSubscriptions$.add(this.matchstore.pipe(select(getMatchesLoadingState))
      .pipe(
        tap( isLoading => {
          this.matchesAreLoading = isLoading;
        })
      )
      .subscribe()
    );

    this.allSubscriptions$.add(this.matchstore
      .pipe(
        select(getMatchesLoadingError),
        tap( error => {
          console.error("getMatchesLoadingError ERROR: ", error);
          if (error !== null && error !== undefined) {
            if (error === "add_search_criterias") {
              this.matchesLoadingError = this.translateService.instant('add_search_criterias');
            } else {
              this.matchesLoadingError = error;
            }
          } else {
            this.matchesLoadingError = error;
          }
        })
      )
      .subscribe()
    );

    this.allSubscriptions$.add(this.matchstore
      .pipe(select(getHydraView))
      .pipe(
        map(hydraView => {
          if (hydraView !== null && hydraView['hydra:next'] !== undefined) {
            this.hasNext = true;
            this.nextPage = hydraView['hydra:next'];
          } else {
            this.hasNext = false;
          }
        })
      )
      .subscribe()
    );
  }

  ionViewDidEnter() {
  }

  ionViewDidLeave() {
  }

  ngOnDestroy() {
    this.allSubscriptions$.unsubscribe();
  }

  reload(event) {
    this.matchesLoadingError = null;
    this.reloadMatches.emit(event);
  }

  loadMoreData(event) {
    this.matchesLoadingError = null;
    if (!this.hasNext) {
      event.target.complete();
      event.target.disabled = true;
    } else {
      event.target.disabled = false;
      event.target.enabled = true;
      this.isLoadingMoreData = true;
      this.matchstore.dispatch(MatchActions.loadMatchs({parameters: {nextPage: this.nextPage}}));
    }
  }

}

import {Injectable} from '@angular/core';
import {Storage} from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class UserTokenService {

  key = 'TOKENS_USER';
  constructor() { }

  add(data: any) {
    return Storage.set({key: this.key, value: JSON.stringify(data)});
  }

  get() {
    return Storage.get({key: this.key}).then(dataString => {
      return JSON.parse(dataString.value);
    });
  }

  getToken() {
    return new Promise((resolve, reject) => {
      /*const tokensUser = async () => {
        const { value } = await Storage.get({key: 'TOKENS_USER'});
        alert(`Hello ${value}!`);
      };*/

      try {

        Storage.get({key: 'TOKENS_USER'}).then(tokens => {
          const tokenArray = JSON.parse(tokens.value);
          if (tokenArray.token) {
            resolve(tokenArray.token);
          } else {
            reject(null);
          }
        }).catch(err => {
          reject(null);
        });

      } catch (e) {
      }

    });


    /*
    return await Storage.get({key: 'TOKENS_USER'}).then(dataString => {
      alert('getToken 2');
      if (!dataString) {
        alert('getToken 3');
        return null;
      }
      alert('getToken 4');
      const data = JSON.parse(dataString.value);
      alert('getToken 5');
      if (data.token) {
        alert('getToken 6');
        return data.token;
      }
      // return null;
    }).catch(e => {
      alert('error getToken');
      return null;
    });
    */
  }

  getRefreshToken() {
    return Storage.get({key: this.key}).then(dataString => {
      if (!dataString.value) {
        return null;
      }
      const data = JSON.parse(dataString.value);
      if (data.refresh_token) {
        return data.refresh_token;
      }
      return null;
    });
  }

  delete() {
    Storage.remove({key: this.key}).then(() => {});
  }
}

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from "../../shared/services/user/auth.service";
import {tap} from "rxjs/operators";
import {ToastService} from "../../shared/services/toast.service";

@Component({
  selector: 'app-phone-code',
  templateUrl: './phone-code.component.html',
  styleUrls: ['./phone-code.component.scss']
})
export class PhoneCodeComponent implements OnInit {

  @Input() phoneCode;
  @Input() phoneNumber;
  @Output() setPhoneCode = new EventEmitter();
  @Output() sendToEmail = new EventEmitter();
  @ViewChild('code1')  code1Element: ElementRef;
  codeInput1: number;
  @ViewChild('code2')  code2Element: ElementRef;
  codeInput2: number;
  @ViewChild('code3')  code3Element: ElementRef;
  codeInput3: number;
  @ViewChild('code4')  code4Element: ElementRef;
  codeInput4: number;

  showEmail = false;

  emailCounter = 60;
  emailEnable = false;
  email: string;

  constructor(private authService: AuthService,
              private toastService: ToastService) { }

  ngOnInit(): void {
    this.decrementCount();
  }

  confirm() {
    this.phoneCode = this.codeInput1 + '' + this.codeInput2 + '' + this.codeInput3 + '' + this.codeInput4;
    this.setPhoneCode.emit(this.phoneCode);
  }

  confirmEmail() {
    if (this.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) !== null) {
      return this.authService.getEmailValidationCode(this.phoneNumber, this.email)
          .pipe(
              tap( data => {
                this.showEmail = false;
              })
          ).subscribe();
    } else {
      this.toastService.presentError('validation_message_not_a_valid_email', 'top');
    }
  }

  changeInput(event, input: string) {
    if (event.key === 'Meta') {
      let digitString = '';
      switch (input) {
        case 'code1':
          digitString = '' + this.codeInput1;
          break;
        case 'code2':
          digitString = '' + this.codeInput1;
          break;
        case 'code3':
          digitString = '' + this.codeInput1;
          break;
        case 'code4':
          digitString = '' + this.codeInput1;
          break;
      }

      const array = digitString.split('');
      array.forEach((item, i) => {
        switch (i) {
          case 0:
            this.codeInput1 = parseInt(item, null);
            break;
          case 1:
            this.codeInput2 = parseInt(item, null);
            break;
          case 2:
            this.codeInput3 = parseInt(item, null);
            break;
          case 3:
            this.codeInput4 = parseInt(item, null);
            break;
        }
      });
    } else {
      switch (input) {
        case 'code1':
          if (this.codeInput1 !== null && this.codeInput1 !== undefined) {
            this.code2Element.nativeElement.focus();
          }
          break;
        case 'code2':
          if (this.codeInput2 !== null && this.codeInput2 !== undefined) {
            this.code3Element.nativeElement.focus();
          }
          break;
        case 'code3':
          if (this.codeInput3 !== null && this.codeInput3 !== undefined) {
            this.code4Element.nativeElement.focus();
          }
          break;
      }
    }
  }
  deleteInput(input: string) {
    switch (input) {
      case 'code2':
        this.code1Element.nativeElement.focus();
        break;
      case 'code3':
        this.code2Element.nativeElement.focus();
        break;
      case 'code4':
        this.code3Element.nativeElement.focus();
        break;
    }
  }

  showInputEmail() {
    if (this.emailEnable) {
      this.sendToEmail.emit();
      this.showEmail = true;
    }
  }

  decrementCount() {
    setTimeout(() => {
      this.emailCounter--;
      if (this.emailCounter !== 0) {
        this.decrementCount();
      } else {
        this.emailEnable = true;
      }
    }, 1000);
  }
}

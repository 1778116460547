import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ClubAllEventsComponent } from 'src/app/club/containers/club-all-events/club-all-events.component';
import { EventDetailComponent } from 'src/app/club/containers/event-detail/event-detail.component';
import { GoogleMapsComponent } from 'src/app/components/google-maps/google-maps.component';
import { HomePage } from 'src/app/home/home.page';
import { Router } from '@angular/router';
import { FiltersPage } from '../../modal/filters/filters.page';
import { PlaceSearchPage } from '../../modal/place-search/place-search.page';
import { BookingSportPage } from '../../modal/booking/booking-sport/booking-sport.page';

import { SignComponent } from '../../modal/auth/sign/sign.component';
import { SignInComponent } from '../../modal/auth/sign-in/sign-in.component';
import { SignUpComponent } from '../../modal/auth/sign-up/sign-up.component';

import { ChoiceActivityComponent } from '../../modal/choice-activity/choice-activity.component';
import { BookingDetailComponent } from '../../modal/booking/booking-detail/booking-detail.component';
import { ClubDetailComponent } from '../../club-detail/club-detail.component';
import { PlayerComponent } from '../../player/player.component';
import { SearchClubPage } from 'src/app/search-club/search-club.page';
import { SearchType } from '../enums/search-type';
import { MatchDetailComponent } from 'src/app/matches/match-detail/match-detail.component';
import { BehaviorSubject } from 'rxjs';
import { MatchCommentsComponent } from 'src/app/matches/match-comments/match-comments.component';
import { ClubNewsDetailsComponent } from 'src/app/club-news/club-news-details/club-news-details.component';
import { UserTokenService } from './storage/user-token.service';
import { FiltersCoursePage } from 'src/app/club-booking-list/components/filtre-course/filters/filters.page';
import { CourseBookingComponent } from 'src/app/club-booking-list/components/course-booking/course-booking.component';
import { CoursePaiementComponent } from 'src/app/club-booking-list/components/paiement/paiement.component';
import {ClassDetailComponent} from "../../club-booking-list/components/class-detail/class-detail.component";
import {MyFriendsComponent} from "../../account/my-friends/my-friends.component";
import {ClassCommentsModalComponent} from "../../club-booking-list/components/class-comments-modal/class-comments-modal.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalClients: HTMLIonModalElement;
  modalSearchClubResult: HTMLIonModalElement;
  modalFilterClub: HTMLIonModalElement;
  modalCourseParticipant: HTMLIonModalElement;
  modalCoursePaiement: HTMLIonModalElement;
  modalDetailClub: HTMLIonModalElement;
  modalBookingSport: HTMLIonModalElement;
  modalAuthSign: HTMLIonModalElement;
  modalAuthSignIn: HTMLIonModalElement;
  modalAuthSignUp: HTMLIonModalElement;
  modalClubDetail: HTMLIonModalElement;
  modalBookingSearch: HTMLIonModalElement;
  modalChoiceActivity: HTMLIonModalElement;
  modalDetailBooking: HTMLIonModalElement;
  matchDetailsModal: HTMLIonModalElement;

  refreshViewSub$ = new BehaviorSubject(false);
  refreshView$ = this.refreshViewSub$.asObservable();

  constructor(
    private modalController: ModalController,
    private router: Router,
    private userTokenService: UserTokenService
  ) { }

  async presentLoginModal() {
    const modal = await this.modalController.create({
      component: HomePage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  async presentGGMapsModal() {
    const modal = await this.modalController.create({
      component: GoogleMapsComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  async presentClubEventsModal(events$) {
    const modal = await this.modalController.create({
      component: ClubAllEventsComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        events$,
        foo: 'zarma'
      }
    });
    return await modal.present();
  }

  async presentClubEventDetailModal(eventId) {
    const modal = await this.modalController.create({
      component: EventDetailComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        eventId,
      }
    });
    return await modal.present();
  }

  playerModal(id) {
    this.modalController.create({
      component: PlayerComponent,
      cssClass: 'player-class',
      componentProps: { id }
    }).then(modal => {
      modal.present().then();
    });
  }

  async presentClubSearchModal() {
    await this.modalController.create({
      component: SearchClubPage
    })
      .then(mod => {
        mod.present();
      });
  }

  // searchType is the app section where to redirect to when search results are clicked
  async searchClubResultModal(searchType?) {
    await this.modalController.create({
      component: PlaceSearchPage,
      componentProps: { searchType }/* ,
      cssClass: 'search-result-class-' + position, */
    }).then(mod => {
      mod.onDidDismiss()
        .then(ret => {
          if (ret.data !== undefined) {
            if (ret.data.clubId) {
              this.showClub(ret.data.clubId, ret.data.selectedView);
            } else if (ret.data.searchType === SearchType.CLUB) {
              this.router.navigate(['/search-club']);
              // this.presentClubSearchModal();
            }
          }
        });
      mod.present().then();
    });
  }

  async filterSearchModal(clubId?) {
    this.modalFilterClub = await this.modalController.create({
      component: FiltersPage,
      cssClass: 'filter-search-class',
      componentProps: {
        clubId
      }
    });

    await this.modalFilterClub.present();
    return this.modalFilterClub;
  }

  async filterCourseSearchModal(clubId?, activities?, filter?) {
    this.modalFilterClub = await this.modalController.create({
      component: FiltersCoursePage,
      cssClass: 'filter-search-class',
      componentProps: {
        clubId,
        activities,
        filter
      }
    });

    await this.modalFilterClub.present();
    return this.modalFilterClub;
  }

  async courseDetailsModal(booking, user?, participated?) {
    this.modalFilterClub = await this.modalController.create({
      component: ClassDetailComponent,
      componentProps: {
        booking,
        user,
        participated
      }
    });

    await this.modalFilterClub.present();
    return this.modalFilterClub;
  }

  async courseCurseBookingModal(booking?, user?, participated?, description?) {
    this.modalCourseParticipant = await this.modalController.create({
      component: CourseBookingComponent,
      backdropDismiss: true,
      cssClass: 'course-details-class',
      componentProps: {
        booking,
        user,
        participated,
        description
      }
    });

    await this.modalCourseParticipant.present();
    return this.modalCourseParticipant;
  }

  async coursePaiementBookingModal(booking?, user?, participated?, cart?, attenders?) {
    this.modalCoursePaiement = await this.modalController.create({
      component: CoursePaiementComponent,
      cssClass: 'course-paiement-class',
      componentProps: {
        booking,
        user,
        participated,
        cart,
        attenders
      }
    });

    await this.modalCoursePaiement.present();
    return this.modalCoursePaiement;
  }

  async closeFilterClubModal() {
    this.modalFilterClub.dismiss();
    this.modalFilterClub = undefined;
  }

  async showClub(id, selectedView = 'informations') {
    this.modalController.create({
      // component: ClubPage,
      component: ClubDetailComponent,
      cssClass: 'club-details-class',
      componentProps: {
        id,
        selectedView
      },
      swipeToClose: true
    }).then(mod => {
      mod.present();
    });
  }

  async closeClubModal() {
    this.modalClubDetail.dismiss();
    this.modalClubDetail = undefined;
  }

  async closeBookingSearchModal() {
    this.modalBookingSearch.dismiss();
    this.modalBookingSearch = undefined;
  }

  bookingSportModal(slotData, playground, duration, activity, blockPrice)  {
    return this.modalController.create({
      component: BookingSportPage,
      cssClass: 'booking-sport-class',
      componentProps: {
        slotData,
        playground,
        duration,
        activity,
        timeTableBlockPricesId: blockPrice['id']
      }
    });
  }

  async closeBookingSportModal() {
    this.modalBookingSport.dismiss();
    this.modalBookingSport = undefined;
  }

  async presentMatchDetails(matchId: string, matchActivityId: string) {
    return await this.modalController
      .create({
        component: MatchDetailComponent,
        cssClass: 'match-details-class',
        componentProps: {
          matchId,
          matchActivityId
        },
        animated: true
      });
    // return await this.matchDetailsModal.present();
    /* .then(modal => {
      modal.onDidDismiss().then( data => {
        return data;

      });
      modal.present();
    }); */
  }

  async presentMatchComments(matchId: string, matchName: string, userId: string, clubName: string, clubLogoUrl: string) {
    const matchCommentsModal = await this.modalController
      .create({
        component: MatchCommentsComponent,
        cssClass: 'match-comments-css',
        componentProps: {
          matchId,
          matchName,
          userId,
          clubName,
          clubLogoUrl
        },
        animated: true
      });
    return await matchCommentsModal.present();
  }

  async presentCourseComments(courseId: string, courseName: string, userId: string, club: any, clubLogoUrl: string) {
    const courseCommentsModal = await this.modalController
        .create({
          component: ClassCommentsModalComponent,
          cssClass: 'match-comments-css',
          componentProps: {
            courseId,
            courseName,
            userId,
            club,
            clubLogoUrl
          },
          animated: true
        });
    return await courseCommentsModal.present();
  }

  async presentNewsDetails(newsIri: string) {
    const matchCommentsModal = await this.modalController
      .create({
        component: ClubNewsDetailsComponent,
        cssClass: 'news-class',
        componentProps: {
          newsIri
        },
        animated: true
      });
    return await matchCommentsModal.present();
  }

  async signModal() {
    this.modalController.create({
      component: SignComponent,
      cssClass: 'sign-class',
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios'
    }).then(modal => {
      modal.dismiss();
      modal.present();
    });
  }

  async closeSignModal() {
    this.modalAuthSign.dismiss();
    this.modalAuthSign = undefined;
  }

  async signInModal() {
    this.modalAuthSignIn = await this.modalController.create({
      component: SignInComponent,
      cssClass: 'sign-class',
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios'
    });
    return await this.modalAuthSignIn.present().then(() => true);
  }

  async closeSignInModal() {
    this.modalAuthSignIn.dismiss();
    this.modalAuthSignIn = undefined;
  }

  async signUpModal() {
    this.modalAuthSignUp = await this.modalController.create({
      component: SignUpComponent,
      cssClass: 'sign-class',
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios'
    });
    return await this.modalAuthSignUp.present();
  }

  async closeSignUpModal() {
    this.modalAuthSignUp.dismiss();
    this.modalAuthSignUp = undefined;
  }

  async choiceActivityModal() {
    this.modalChoiceActivity = await this.modalController.create({
      component: ChoiceActivityComponent
    });
    return await this.modalChoiceActivity.present();
  }

  presentBookingDetailmodal(bookingIri) {
    return this.modalController.create({
      component: BookingDetailComponent,
      cssClass: 'reservation-class',
      componentProps: {
        bookingIri
      }
    }).then(mod => {
      mod.present().then();
      return mod.onDidDismiss().then(data => {
        if (data.data !== undefined && data.data['refresh'] === true) {
          this.refreshViewSub$.next(true);
        }
      });
    });
  }

  presentFriend() {
    this.userTokenService.getToken().then((token: any) => {
      if (token) {
        return this.modalController.create({
          component: MyFriendsComponent,
          cssClass: 'my-component-open-class',
          componentProps: {
            userId: null
          }
        }).then(modal => {
          modal.present().then();
          modal.onDidDismiss().then(data => {
          });
        }).catch(e => {
          console.log('err : ', e);
        });
      } else {
        this.signModal().then();
      }
    }).catch(e => {
      this.signModal().then();
    });

  }

  presentModal(component: any, props: any = {}, cssClass: string = 'my-custom-class') {
    return this.modalController.create({
      component: component,
      componentProps: props,
      cssClass: cssClass,
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios'
    });
  }

  dismissModal(data?: any, role?: string, id?: string) {
    return this.modalController.dismiss();
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { numberToArray } from 'src/app/shared/helpers/number_to_array';


@Component({
  selector: 'app-club-review-short',
  templateUrl: './club-review-short.component.html',
  styleUrls: ['./club-review-short.component.scss']
})
export class ClubReviewShortComponent implements OnInit {
  clubRating$: Observable<any>;
  clubReviewsNumber$: Observable<any>;

  constructor() { }

  ngOnInit() {
    // TODO Hardcoded before api implementation
    this.clubRating$ = of(5).pipe(
      map(val => numberToArray(val))
    );
    this.clubReviewsNumber$ = of(20);
  }



}

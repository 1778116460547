import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, tap, map, takeLast, take } from 'rxjs/operators';
import { Period } from 'src/app/shared/enums/period';
import { User } from 'src/app/shared/models/user';
import { AppState } from 'src/app/state/app.state';
import { BookingService } from '../../shared/services/booking/booking.service';
import * as SortEvents from 'src/app/shared/helpers/sort-events-by-date';
import { Booking } from 'src/app/shared/models/booking';
import { ModalService } from 'src/app/shared/services/modal.service';

import {EnvironmentService} from "../../shared/services/environment/environment.service";


@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss']
})
export class MyBookingsComponent implements OnInit, AfterViewInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  env;
  user: User;
  bookings: Array<any>;
  selectedView: string;
  showSkeleton: boolean;
  displayErrorMessage: boolean;
  hasNext: boolean;
  nextPage: any;
  pastBookings: any[];
  pastBookingsByDate: any[];
  yesterdayBookings: any[];
  todayBookings: any[];
  tomorrowBookings: any[];
  otherDaysBookings: any[];
  otherDaysBookingsByDate: any[];
  displayNoMatchMessage: boolean;

  constructor(
    private modalCtrl: ModalController,
    private bookingService: BookingService,
    private store: Store<AppState>,
    private modalService: ModalService,
    private environmentService: EnvironmentService
  ) {
    this.env = this.environmentService.getEnvFile();
    this.selectedView = 'next';
    this.pastBookings = [];
    this.pastBookingsByDate = [];
    this.yesterdayBookings = [];
    this.todayBookings = [];
    this.tomorrowBookings = [];
    this.otherDaysBookings = [];
    this.otherDaysBookingsByDate = [];
    this.hasNext = false;
    this.nextPage = null;
  }

  ngOnInit(): void {
    this.load(this.selectedView, true);
  }

  ngAfterViewInit() {
    this.modalService.refreshView$.pipe(
      filter(Boolean),
      tap( refresh => this.load('next', true))
    )
    .subscribe();
  }

  segmentChanged(event) {
    this.selectedView = event.detail.value;
    this.load(event.detail.value, true);
  }

  async openBooking(booking) {
    if (booking.activityType === 'lesson') {
      this.modalService.courseDetailsModal(booking, this.user).then(mod => {
        mod.onDidDismiss().then( data => {
        });
      });
    } else {
      await this.modalService.presentBookingDetailmodal(booking['@id'])
          .then(modal => {

          });
    }
  }

  returnRequest(status: Period, nextPage?) {
    if (nextPage) {
      return this.bookingService.getMyBookingsNextPage(nextPage);
    } else {
      if (status === 'canceled') {
        return this.bookingService.getMyBookings(true, status, null, null, this.user.id);
      } else {
        return this.bookingService.getMyBookings(false, status, null, null, this.user.id);
      }
    }
  }

  load(status, reset = true, nextPage = null ) {
    this.displayErrorMessage = false;
    // TODO: unsubscribe from OBSERVABLES in OnDestroy
    if (reset) {
      this.bookings = [];
      this.showSkeleton = true;
      this.pastBookings = [];
      this.pastBookingsByDate = [];
      this.yesterdayBookings = [];
      this.todayBookings = [];
      this.tomorrowBookings = [];
      this.otherDaysBookings = [];
      this.otherDaysBookingsByDate = [];
      this.hasNext = false;
      this.nextPage = null;
    }

    this.store.select('user').subscribe(
      async (userClient) => {
        this.user = userClient;
        if (userClient !== null) {
          const data = await this.returnRequest(status, nextPage).toPromise();
          if (data !== undefined) {
            if (data['hydra:view']['hydra:next']) {
              this.hasNext = true;
              this.nextPage = data['hydra:view']['hydra:next'];
              if (this.infiniteScroll) {
                this.infiniteScroll.complete();
                this.infiniteScroll.disabled = false;
              }
            } else {
              this.hasNext = false;
              if (this.infiniteScroll) {
                this.infiniteScroll.complete();
                this.infiniteScroll.disabled = true;
              }
            }

            if (reset) {
              this.bookings = data['hydra:member'];
            } else {
              this.bookings = [...this.bookings, ...data['hydra:member']];
            }
            if (this.bookings.length === 0) {
              this.displayNoMatchMessage = true;
            } else {
              const sorted = SortEvents.byDate(this.bookings, status);
              // this.sortByDate(this.matches, Period.CANCELED);
              this.pastBookings = sorted.pastEvents;
              this.pastBookingsByDate = sorted.pastEventsByDate;
              this.yesterdayBookings = sorted.yesterdayEvents;
              this.todayBookings = sorted.todayEvents;
              this.tomorrowBookings = sorted.tomorrowEvents;
              this.otherDaysBookings = sorted.otherDaysEvents;
              this.otherDaysBookingsByDate = sorted.otherDaysEventsByDate;
              this.displayNoMatchMessage = false;
            }
            this.showSkeleton = false;
          } else {
            this.bookings = [];
            this.displayErrorMessage = true;
          }
        }
    });
  }

  loadMoreData(event) {
    this.load(this.selectedView, false, this.nextPage);
  }

  close() {
    this.modalCtrl.dismiss({refresh: true});
  }
}

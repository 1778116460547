import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import {EnvironmentService} from "../../../shared/services/environment/environment.service";

@Component({
  selector: 'app-booking-succes-modal',
  templateUrl: './booking-succes-modal.component.html',
  styleUrls: ['./booking-succes-modal.component.scss']
})
export class BookingSuccesModalComponent implements OnInit {
  @Input() bookingIRI: any;
  @Input() msgCourse: any;

  env;

  constructor(
    private modalController: ModalController,
    private environmentService: EnvironmentService
  ) {
    this.env = this.environmentService.getEnvFile();
  }

  ngOnInit(): void {
  }

  onScreenTapped() {
    this.modalController.dismiss({createMatch: false, bookingIRI: this.bookingIRI});
  }

  goCreateMatch() {
    this.modalController.dismiss({createMatch: true});
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavController} from '@ionic/angular';
import {BookingService} from '../../../shared/services/booking/booking.service';

@Component({
  selector: 'app-nav-bar-club',
  templateUrl: './nav-bar-club.component.html',
  styleUrls: ['./nav-bar-club.component.scss']
})
export class NavBarClubComponent {
  @Input() selectedView: string;
  @Output() changeView = new EventEmitter();
  constructor(
      private navCtrl: NavController,
      private bookingService: BookingService
  ) {}
  segmentChanged(event) {
    this.selectedView = event.detail.value;
    this.changeView.emit(this.selectedView);
  }

}

import * as UserActions from '../actions/user.actions';
import {User} from '../../shared/models/user';

export function UserReducers(state: User = null, action: UserActions.Actions): User {
    switch (action.type) {
        case UserActions.ADD_USER:
            return action.payload;
        case UserActions.REMOVE_USER:
            return null;
        default:
            return state;
    }
}

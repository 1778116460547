import {AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigService } from '../../../config/config.service';
import { AuthService } from '../../../shared/services/user/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserTokenService } from '../../../shared/services/storage/user-token.service';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state/app.state';
import { SignInComponent } from '../sign-in/sign-in.component';
import intlTelInput from 'intl-tel-input';
import { LoaderService } from '../../../shared/services/loader/loader.service';

import { KeyboardInfo } from '@capacitor/keyboard';
import { PhotoService } from 'src/app/shared/services/photo.service';
import { BehaviorSubject, from, fromEvent, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import * as UserActions from '../../../state/actions/user.actions';
import { User } from 'src/app/shared/models/user';
import { OneSignalServiceService } from 'src/app/shared/services/oneSignal/one-signal-service.service';
import { getPrimaryColor } from 'src/utils/get-primary-color';
import { getSecondaryColor } from 'src/utils/getSecondaryColor';

import {EnvironmentService} from "../../../shared/services/environment/environment.service";
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewInit {
  @ViewChild('phoneInput') phoneInput: ElementRef;

  PHOTO_STORAGE = "avatar";
  env;
  initialCountryCode = "fr";
  phoneNumber: string;
  selectedCountry: string;
  separateDialCode: boolean;
  SearchCountryField: any;
  TooltipLabel: any;
  CountryISO: any;
  preferredCountries: Array<any>;
  phoneForm: any;
  logoUrl: string;
  errorPhone: boolean;
  errorPhoneMessage: string;
  password: string;
  stepLogin: number;
  showPhoneCode: boolean;
  phoneCode: number;
  showPhoneInput: boolean;
  showEmailInput: boolean;
  showUseForm: boolean;
  token: string;
  iti: any;
  isFocus: boolean;
  errorUserForm = {
    error: false,
    message: ''
  };

  welcome = '';
  passwordImage = '';
  signUpErrorsSub$ = new BehaviorSubject <any>(null);
  signupErrors$ = this.signUpErrorsSub$.asObservable();
  secondaryColorIsWhite: boolean;

  constructor(
    private config: ConfigService,
    private authService: AuthService,
    private userToken: UserTokenService,
    private modalCtr: ModalController,
    public translate: TranslateService,
    private loaderService: LoaderService,
    private photoService: PhotoService,
    private appStore: Store<AppState>,
    private oneSignalService: OneSignalServiceService,
    private environmentService: EnvironmentService
  ) {
    this.env = this.environmentService.getEnvFile();
    this.isFocus = false;
    this.showBlock('input_phone');
    // this.showBlock('user_form');
    this.selectedCountry = '33';
    this.stepLogin = 1;
    this.logoUrl = this.config.getLogoUrl();
    this.separateDialCode = false;
    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required])
    });
    this.errorPhone = false;
    this.errorPhoneMessage = '';
    this.initialCountryCode = this.env.defaultPhoneLang;
    this.welcome = 'assets/images/illustrations' + (this.env.useMb ? ('_' + this.env.marqueBlanche.pathName) : '')  + '/welcome.svg';
    this.passwordImage = 'assets/images/illustrations' + (this.env.useMb ? ('_' + this.env.marqueBlanche.pathName) : '')  + '/create_password.svg';
  }

  ngOnInit(): void {
    if (window.origin.includes('capacitor://')) {
      Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
      });
    }
    const secondaryColor = getSecondaryColor();
    if (secondaryColor && secondaryColor === 'ffffff') {
      this.secondaryColorIsWhite = true;
    } else {
      this.secondaryColorIsWhite = false;
    }
  }

  ngAfterViewInit() {
    const input = document.querySelector("#phoneUp");
    this.iti = intlTelInput(input, {
      customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        if (selectedCountryData.dialCode === '262') {
          return "Réunion (+262)"
        } else {
          return selectedCountryPlaceholder;
        }
      },
      // any initialisation options go here
      utilsScript: "src/utils/utils.js",
      initialCountry: this.env.defaultPhoneLang,
      onlyCountries: ['ae', 'be', 'ch', 'ci', 'de', 'es', 'fr', 'gb', 'gp', 'it', 'lu', 'mq', 'nl', 'pt', 're', 'sn', 'mu', 'gf', 'th', 'se', 'mc', 'ma', 'nc', 'mg', 'dz']
    });

    fromEvent(this.phoneInput.nativeElement, 'keyup')
      .pipe(
        map( event => {
          return this.phoneInput.nativeElement.value;
        }),
        tap( (event: any) => {
          this.errorPhoneMessage = '';
        }),
       /*  filter(Boolean),
        filter((event: any) => event.length > 9),
        debounceTime(500),
        distinctUntilChanged(),
        tap( phoneNumber => {
          this.errorPhoneMessage = '';
          this.requestPhoneCode(true);
        }) */
      ).subscribe();
  }


  updateUrlWelcome(error) {
    this.welcome = 'assets/images/logos/icon_doin.png';
  }

  updateUrlPassword(error) {
    this.passwordImage = 'assets/images/logos/icon_doin.png';
  }

  phoneChange(event) {
    this.phoneNumber = event.target.value;
    this.errorPhoneMessage = '';
    this.errorPhone = false;
  }

  passwordChange() {
    this.errorPhoneMessage = '';
    this.errorPhone = false;
  }

  countryChange(event) {
    this.selectedCountry = event.dialCode;
    this.errorPhoneMessage = '';
    this.errorPhone = false;
  }

  validatePhoneNumber(event) {
    // alert("event: " + event );
    // this.requestPhoneCode();
  }

  requestPhoneCode(waitBeforeSending = false) {
    this.errorPhoneMessage = '';
    if (waitBeforeSending === false) {
      this.loaderService.presentLoading();
    }
    this.phoneNumber = this.iti.getNumber();
    if (this.phoneNumber === '' || this.phoneNumber === undefined) {
      this.errorPhoneMessage = this.translate.instant('user_phone_not_valid');
      this.errorPhone = true;
    } else {
      const phoneFormated = this.phoneNumber;
      this.authService.checkIfUserExists(phoneFormated)
        .pipe(
          tap( resp => {
            this.loaderService.dismiss();
          }),
          filter( user => user !== undefined),
          tap( user => {
            if (user.exist) {
              this.errorPhone = true;
              this.errorPhoneMessage = this.translate.instant('phone_number_already_used');
            }
          }),
          switchMap( user => {
            if (!user.exist) {
              return this.authService.getPhoneNumberValidationCode(phoneFormated)
                .pipe(
                  tap(resp => {
                    this.loaderService.dismiss();
                    if (resp !== undefined && waitBeforeSending === false) {
                      this.errorPhoneMessage = "";
                      this.showBlock('code_sms');
                    } else if (resp !== undefined && waitBeforeSending === true) {
                      this.errorPhoneMessage = "";
                    } else {
                      this.errorPhoneMessage = this.translate.instant('user_phone_not_valid');
                    }
                  })
                );
            }
          }),
          catchError( resp => of(this.loaderService.dismiss()))
        )
        .subscribe(exit => {

        });
    }
  }

  showBlock(version) {
    if (version === 'user_form') {
      this.showPhoneInput = false;
      this.showEmailInput = false;
      this.showPhoneCode = false;
      this.showUseForm = true;
      this.stepLogin = 2;
    } else if (version === 'code_sms') {
      this.showEmailInput = false;
      this.showPhoneInput = false;
      this.showPhoneCode = true;
      this.showUseForm = false;
      this.stepLogin = 1;
    } else if (version === 'input_email') {
      this.showPhoneInput = false;
      this.showEmailInput = true;
      this.showPhoneCode = false;
      this.showUseForm = false;
      this.stepLogin = 1;
    } else if (version === 'input_phone') {
      this.showEmailInput = false;
      this.showPhoneInput = true;
      this.showPhoneCode = false;
      this.showUseForm = false;
      this.stepLogin = 1;
    }
  }

  confirmPhoneCode(code) {
    this.loaderService.presentLoading();
    this.phoneCode = code;
    this.authService.verifyPhoneNumberValidationCode(this.phoneNumber, this.phoneCode).subscribe(data => {
      if (data) {
        // this.userToken.add(data);
        this.token = data.token;
        this.showBlock('user_form');
      }
      this.loaderService.dismiss();
    });
  }

  confirmUserForm(event) {
    /* if (!this.loaderService.loading) {
      this.loaderService.presentLoading();
    } */
    this.authService.signupUser(event, this.token)
      .pipe(
        catchError( errors => {
          this.signUpErrorsSub$.next(errors);
          return of(console.error("signupUser error: ", JSON.stringify(errors, null, 4)));
        })
      )
      .subscribe(resp => {
        this.loaderService.dismiss();
        if (resp !== undefined) {
          this.appStore.dispatch(new UserActions.AddUser(resp as User));
          this.signUp(event);
        }
      });
  }

  signUp(data) {
    this.loaderService.presentLoading();

    this.authService.signInUser(this.phoneNumber, data.plainPassword)
      .pipe(
        filter(resp => resp !== undefined),
        switchMap( resp => {
          return from(this.userToken.add(resp));
        }),
        switchMap( _ => {
          return this.photoService.avatarAsBlob$;
        }),
        /* tap(avatar => {
          this.loaderService.dismiss();
        }), */
        switchMap( avatarAsBlobObject => {

          if (avatarAsBlobObject !== undefined && avatarAsBlobObject !== null) {
            // tslint:disable-next-line: max-line-length
            return this.photoService.uploadPic(avatarAsBlobObject.blob, avatarAsBlobObject.imageName, avatarAsBlobObject.capturedPhotoFormat);
          } else {
            return of(null);
          }
        }),
        tap( resp => {
          // TODO : There add OneSignalService.AddOneSignal
          this.oneSignalService.addOnesignalIdUser();
          this.loaderService.dismiss();
          this.close();
        })
      )
      .subscribe();
  }

  async goToSignIn() {
    this.modalCtr.dismiss().then( () => {});
    const modal = await this.modalCtr.create({
      component: SignInComponent,
      cssClass: 'sign-class',
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios',
      // presentingElement: await this.modalCtr.getTop()
    });
    modal.present().then(() => {});
  }

  close() {
    this.modalCtr.dismiss().then(() => {});
  }

  goToWelcome() {
    this.modalCtr.dismiss().then(() => {});
    // TODO : Open modal welcome !
  }
}

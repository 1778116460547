import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription, Subject } from 'rxjs';
import { Booking } from '../../../shared/models/booking';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { BookingService } from '../../../shared/services/booking/booking.service';
import { Period } from 'src/app/shared/enums/period';
import { ClubIdStorageService } from 'src/app/shared/services/clud-id-storage/club-id-storage.service';
import { ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'app-last-booking',
  templateUrl: './last-booking.component.html',
  styleUrls: ['./last-booking.component.scss'],
})
export class LastBookingComponent implements OnDestroy, ViewWillEnter {
  @Input() refresh$: Observable<boolean> = of(false);
  @Input() noChange: boolean = false;
  @Input() userMe: any;
  @Input() bookings: any;
  @Input() nextBookings: any;
  @Input() pastBookings: any;
  @Output() noBooking = new EventEmitter<boolean>();
  @Output() refreshMatches = new EventEmitter<any>(false);
  @Input() clubSelected: any;
  @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();

  // refresh$: Observable<boolean>;
  updateBookingsSub$ = new BehaviorSubject(false);
  updateBookings$ = this.updateBookingsSub$.asObservable();
  showSkeleton = true;
  isConnected: boolean;
  subscription$: Subscription;
  Period = Period;
  clubId: any;
  slideOpt = {
    slidesPerView: 2
  };

  slideOptTablet = {
    slidesPerView: 4
  };

  constructor(
      private bookingService: BookingService,
      private store: Store<AppState>,
      private clubIdStorageService: ClubIdStorageService
  ) {
    this.isConnected = false;
  }

  ionViewWillEnter() {
    if (this.bookings){
      this.showSkeleton = false;
    }

  }

  ngOnDestroy() {
    if (this.subscription$ !== undefined) {
      this.subscription$.unsubscribe();
    }
  }

  reload() {
    this.updateBookingsSub$.next(true);
    this.refreshMatches.emit(true);
  }
}

import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output} from '@angular/core';
import {Booking, BookingReceipt} from '../../shared/models/booking';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {ModalService} from '../../shared/services/modal.service';

import { Period } from 'src/app/shared/enums/period';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

export enum BookingCardConfig {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE"
}

@Component({
  selector: 'app-card-my-booking',
  templateUrl: './card-my-booking-v2.component.html',
  styleUrls: ['./card-my-booking-v2.component.scss'],
})
export class CardMyBookingComponent implements OnDestroy, OnInit {
  @Input() booking: BookingReceipt|Booking;
  @Input() userMe: any;
  @Input() bookingStatus: Period;
  @Input() cardConfig: BookingCardConfig = BookingCardConfig.LARGE;
  @Output() reload = new EventEmitter();

  Period = Period;
  clubSubscription$: Subscription;
  pathUrl: string;
  BookingCardConfig = BookingCardConfig;
  env;

  constructor(
    private modalService: ModalService,
    private environmentService: EnvironmentService
  ) {
    this.env = environmentService.getEnvFile();
    this.pathUrl = environmentService.getEnvFile().pathFiles;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.clubSubscription$) {
      this.clubSubscription$.unsubscribe();
    }
  }

  getDuration(): number {
    let duration;
    const start = moment(this.booking.startAt);
    const end = moment(this.booking.endAt);

    duration = end.diff(start, 'minutes');
    return duration;
  }

  async openBooking() {
    if ("activityType" in this.booking && this.booking.activityType === 'lesson') {
      this.modalService.courseDetailsModal(this.booking, this.userMe).then(mod => {
        mod.onDidDismiss().then( data => {
        });
      });
    } else {
      await this.modalService.presentBookingDetailmodal(this.booking['@id'])
          .then(modal => {

          });
    }
  }
}

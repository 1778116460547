import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { select, Store } from '@ngrx/store';
import { AppState } from './state/app.state';
import { slideInAnimation } from './route-animation';
import { AuthService } from './shared/services/user/auth.service';
import { GeolocationService } from './shared/services/geolocation/geolocation.service';
import { UserService } from './shared/services/storage/user.service';
import {take, tap} from 'rxjs/operators';
import * as moment from 'moment';
import * as SelectedDateActions from './state/actions/selectedDate.actions';
import * as ValidatedSearchActions from './state/actions/validated-search.actions';
import { OneSignalServiceService } from './shared/services/oneSignal/one-signal-service.service';
import { NotificationService } from './shared/services/messages/notification.service';
import { LocaleService } from './shared/services/translate/locale.service';
import { ClubService } from './club/club.service';
import { Subscription } from 'rxjs';
import { ClubActions } from './club/store/actions';
import { EnvironmentService } from './shared/services/environment/environment.service';
import { ClubState } from './club/store/club.reducers';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import {UpdateAppModalComponent} from "./modal/update-app-modal/update-app-modal.component";
import {HttpClient} from "@angular/common/http";
import {MyBookingsComponent} from "./account/my-bookings/my-bookings.component";
// import { BranchIo } from '@awesome-cordova-plugins/branch-io/ngx';

declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [slideInAnimation],
  // providers: [BranchIo]
})
export class AppComponent implements AfterViewInit, OnInit, AfterViewInit, OnDestroy {
  activePageTitle = 'Dashboard';
  selectedIndex = 1;
  appPages = [];
  dataUrl = 'assets/app-menu.json';
  $clubSubcription: Subscription;
  usedMB: 'lamaisondusquash';
  env;
  useSidemenu = true;

  accountMe: any = null;
  userSub$: Subscription;

  constructor(
      private http: HttpClient,
      private platform: Platform,
      private authService: AuthService,
      public appStore: Store<AppState>,
      private geolocationService: GeolocationService,
      private userService: UserService,
      private oneSignalService: OneSignalServiceService,
      private store: Store<AppState>,
      private notificationService: NotificationService,
      private localeService: LocaleService,
      private clubStore: Store<ClubState>,
      private clubService: ClubService,
      private environmentService: EnvironmentService,
      private modalController: ModalController,
      //private branch: BranchIo
  ) {
    this.env = environmentService.getEnvFile();
    if (this.env.useMb) {
      this.setCludId().then(() => {
        this.initializeApp();
      });
    } else {
      this.initializeApp();
    }
    this.userSub$ = this.store.select('user').pipe(tap(user => {
      if (user) {
        this.accountMe = user;
      } else {
        this.accountMe = null;
      }
    })).subscribe();
  }

  ngOnInit(): void {
    this.loadMenu();

    Storage.get({key: 'locale'}).then(data => {
      if (data.value) {
        this.localeService.setLanguage(data.value);
      } else {
        this.localeService.setLanguage(this.env.defaultLang);
      }
    })

    // BranchIo
    this.platform.resume.subscribe(() => {
      // branchInit();
    });
/*
    const branchInit = () => {
      this.branch.initSession()
        .then(data => {
          alert('Deep Link Data: ' + JSON.stringify(data));

          if (data['+clicked_branch_link']) {
            switch (data['target_type']) {
              case 'match':
                // REDIRECT TO MATCHES
                break;
              case 'newUser':
                //
                break;
              case 'select-booking':
                //
                break;
              case 'LoginPage':
                //
                break;
              case 'web-link':
                window.open(data['target_id'], "system", 'location=yes');
                break;
            }
          }
        })
        .catch(error => console.log("Branch init ERROR: ", error));
    };*/
  }

  checkMenuSelected() {
    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title?.toLowerCase() === path?.toLowerCase());
    }
  }

  loadMenu() {
    this.http.get<any>(this.dataUrl).pipe(tap(response => {
      this.appPages = response.menu;
      this.checkMenuSelected();
    })).subscribe();
  }

  ngAfterViewInit() {
  }

  // Peut servir si la pop-up dechoix de club serait enlever
  async setCludId() {
    Storage.get({key: "clubId"}).then(value => {
      if (value.value) {
        if (this.environmentService.getEnvFile().marqueBlanche.clubIds.includes(value.value)) {
          return this.setClubStorageDefault(value.value);
        } else {
          return this.setClubStorageDefault(this.environmentService.getEnvFile().marqueBlanche.clubIds[0]);
        }
      }

      return this.setClubStorageDefault(this.environmentService.getEnvFile().marqueBlanche.clubIds[0]).then();
    }).catch(err => {
      console.log(err);
    });
  }

  async setClubStorageDefault(clubId: string) {
    await this.clubStore.dispatch(ClubActions.loadClub({clubId}));
    Storage.set({key: 'clubId', value: clubId}).then(() => true);
  }

  initializeApp() {
    this.loadTagManager();
    this.env = this.environmentService.getEnvFile();
    SplashScreen.show().then();
    this.platform.ready()
      .then(() => {
        try {
          if ((this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
            this.oneSignalService.initOneSignal();
          }
        } catch (e) {
          console.log('error init OneSignal : ', e.stack, e.message);
        }


        if (this.platform.is('ios')) {
          //StatusBar.hide();
          StatusBar.setOverlaysWebView({ overlay: true }).then();
        } else {
          StatusBar.setOverlaysWebView({ overlay: false }).then();
        }
        StatusBar.setStyle({ style: Style.Light }).then();

        if (this.platform.is("hybrid")) {

          //StatusBar.setBackgroundColor({ color: '#FFFFFF' }).then();

          SplashScreen.hide().then();

          this.oneSignalService.initOneSignal();

          /*this.branch.getFirstReferringParams()
            .then(data => {
              console.log('this.branch.getFirstReferringParams() - data : ', data);
            })
            .catch(err => {
              console.log('this.branch.getFirstReferringParams() - data - ERROR : ', err);
            });*/

        }
        this.getUserLocation();
        this.getLoggedUser();

        /*this.appStore.pipe(
          select("validatedSearch"),
          take(1)
        )
          .pipe(
          tap(search => {
            if (search === "") {
              Storage.get({key: 'RECENT_PLACE_SEARCH'})
                  .then(rpc => {
                    if (rpc.value !== undefined && rpc.value !== null) {
                      const recentPlaceSearch = JSON.parse(rpc.value);
                      if (recentPlaceSearch !== undefined && recentPlaceSearch.cities && recentPlaceSearch.cities.length) {
                        this.appStore.dispatch(new ValidatedSearchActions.AddValidatedSearch(recentPlaceSearch.cities[0].toLowerCase()));
                      }
                    }
                  });
              }
            })
          )
          .subscribe();*/
      });

    this.clubService.appNeedUpdate(this.env.marqueBlanche.clubIds[0]).subscribe(data => {
      const whiteLabelInfo = data;
      if (whiteLabelInfo) {
        const strLastVersionApp: string = whiteLabelInfo.appVersion ? whiteLabelInfo.appVersion : '0';
        const lastVersionApp = strLastVersionApp.split('.');
        const  envVersionApp = this.env.versionApp.split('.');
        let numberLastVersion = '';
        lastVersionApp.forEach(nb => {
          numberLastVersion = numberLastVersion + nb;
        });
        let numberEnvVersion = '';
        envVersionApp.forEach(nb => {
          numberEnvVersion = numberEnvVersion + nb;
        });
        if (numberEnvVersion < numberLastVersion) {
          if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
            this.modalController.create({
              component: UpdateAppModalComponent,
              cssClass: 'sign-class',
              componentProps: {
                androidUrl: whiteLabelInfo.googleAppLink,
                appleUrl: whiteLabelInfo.appleAppLink
              },
              backdropDismiss: false,
              animated: true
            }).then(modal => {
              modal.onDidDismiss()
                  .then(result => {
                  });
              modal.present();
            });
          }
        }
      }
    });

    // ### Set date to store
    const date = moment().minute(0).hour(0).second(0).millisecond(0).utc(true);
    this.store.dispatch(new SelectedDateActions.AddSelectedDate(date));

    setTimeout(_ => {
      this.notificationService.getAllNotifications()
        .pipe(
          take(1)
        )
        .subscribe();
    }, 1000);

  }

  getLoggedUser() {
    this.authService.getConnectedUser()
      .pipe(
        tap(data => {
          if (!data) {
            this.authService.logout();
          }
        })
      )
      .subscribe();
  }

  getUser() {
    this.userService.get()
      .subscribe();
  }

  getUserLocation() {
    /* TODO: delete parameter just for testing */
    this.geolocationService.getCurrentPosition("APP_COMPONENT");
  }

  getClubInformation() {
    this.$clubSubcription = this.clubService.getClub(this.env.marqueBlanche.clubIds[0]).subscribe(
      club => this.store.dispatch(ClubActions.loadClubSuccess({ club }))
    );
  }

  ngOnDestroy(): void {
    if (this.$clubSubcription) {
      this.$clubSubcription.unsubscribe();
    }
    if (this.userSub$) {
      this.userSub$.unsubscribe();
    }
  }

  loadTagManager() {
    if (this.environmentService.isPasserelle() && this.environmentService.isThisMB('urbanroundnet')) {
      const script1 = document.createElement("script");
      script1.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KVM6F2D');
          `;
      document.head.appendChild(script1);
      const script2 = document.createElement("noscript");
      script2.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KVM6F2D" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(script2);
    }
  }

  showMyBookings() {
    this.modalController.create({
      component: MyBookingsComponent,
      cssClass: 'my-component-open-class',
      componentProps: {
        userId: this.accountMe.id
      }
    })
        .then(modal => {
          modal.present().then();
          modal.onDidDismiss().then( data => {
          });
        });
  }

}


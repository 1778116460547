import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../../config/config.service';
import {ModalController, NavController} from '@ionic/angular';
import {SignUpComponent} from '../sign-up/sign-up.component';
import {SignInComponent} from '../sign-in/sign-in.component';
import {TranslateService} from '@ngx-translate/core';
import { getSecondaryColor } from 'src/utils/getSecondaryColor';
import {EnvironmentService} from "../../../shared/services/environment/environment.service";
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

  logoUrl: string;
  env;
  secondaryColorIsWhite: boolean;
  logoImage = '';

  constructor(
      private config: ConfigService,
      private nav: NavController,
      private modalCtr: ModalController,
      private loaderService: LoaderService,
      public translate: TranslateService,
      private environmentService: EnvironmentService
  ) {
    this.env = this.environmentService.getEnvFile();
    this.logoUrl = this.config.getLogoUrl();
    this.logoImage = 'assets/images/illustrations' + (this.env.useMb ? ('_' + this.env.marqueBlanche.pathName) : '')  + '/login-bg-app.png';
  }

  ngOnInit(): void {
    this.loaderService.dismiss();
    const secondaryColor = getSecondaryColor();
    if (secondaryColor && (secondaryColor === 'ffffff' || secondaryColor === 'FFFFFF' || secondaryColor === 'white')) {
      this.secondaryColorIsWhite = true;
    } else {
      this.secondaryColorIsWhite = false;
    }
  }

  updateUrlLogo(error) {
    this.logoUrl = 'assets/images/logos/icon_doin.png';
    this.logoImage = 'assets/images/logos/icon_doin.png';
  }


  async goToSignIn() {
    this.modalCtr.dismiss().then( () => {});
    const modal = await this.modalCtr.create({
      component: SignInComponent,
      cssClass: 'sign-class',
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios',
      // presentingElement: await this.modalCtr.getTop()
    });
    modal.present().then(() => {});
    modal.onDidDismiss().then(async (data) => {
  });
  }

  async goToSignUp() {
    this.modalCtr.dismiss().then();
    const modal = await this.modalCtr.create({
      component: SignUpComponent,
      cssClass: 'sign-class',
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios',
      // presentingElement: await this.modalCtr.getTop()
    });
    modal.present().then(() => {});
  }

  close() {
    this.modalCtr.dismiss().then(() => {});
  }
}

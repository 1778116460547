import { Booking } from 'src/app/shared/models/booking';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { slideInSlideOut } from 'src/app/animations';
import { DateUtil } from 'src/app/shared/Tools/date-utils';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment-timezone';
import { getClubCurrency, getClubStripeAccountReference, getCurrentClubPhotos } from 'src/app/club/store';
import { AuthService } from 'src/app/shared/services/user/auth.service';
import { map, switchMap, tap, filter, last } from 'rxjs/operators';
import { ClubState } from 'src/app/club/store/club.reducers';
import { Store, select } from '@ngrx/store';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import { SelectFriendsComponent, SelectFriendsConfig } from 'src/app/components/friends/select-friends/select-friends.component';
import { SignComponent } from 'src/app/modal/auth/sign/sign.component';
import { getCurrentClub } from 'src/app/club/store';
import { PaymentCardService } from 'src/app/shared/services/storage/payment-card.service';
import { AccountService } from 'src/app/shared/services/account/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentCard } from 'src/app/shared/models/payment-card';
import { StripeService } from 'src/app/shared/services/payment/stripe.service';
import { Cart } from 'src/app/shared/models/cart';
import { ManagePaymentService } from 'src/app/shared/services/payment/manage-payment.service';
import { BookingService } from 'src/app/shared/services/booking/booking.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { BookingSuccesModalComponent } from '../booking-succes-modal/booking-succes-modal.component';
import { ClubIdStorageService } from 'src/app/shared/services/clud-id-storage/club-id-storage.service';
import { ClubService } from 'src/app/shared/services/club/club.service';


@Component({
    selector: 'app-paiement',
    templateUrl: './paiement.component.html',
    styleUrls: ['./paiement.component.scss'],
    animations: [
        slideInSlideOut
    ]
})
export class CoursePaiementComponent implements OnInit, OnDestroy {

    @Input() booking;
    @Input() userMe;
    @Input() participated;
    @Input() cart: Cart;
    @Input() attenders;

    clubReal: any;
    paymentCard: Array<PaymentCard>;
    imageUrl: string;
    baseUrl: string = this.environmentService.getEnvFile().domainAPI;
    countPlace = 1;
    avatarBgColor = 'lightGray';
    restToPay = 0;
    pathFile = this.environmentService.getEnvFile().pathFiles;
    priceTotalList: any;
    totalOption: any;
    club: any;
    paymentMetadata: any;
    defaultCard: any;
    restToPay2: any;
    client: any;
    guid: any;

    price: any;
    wallet: any;
    loaded = false;
    selectedMethod: any;

    card$: Observable<any>;
    withWallet = false;
    withCredit = false;
    paymentsProvider: Array<string>;
    selectedProvider: string;
    cartPayment: any;
    isPaid = false;
    isReady = false;
    realClient: any;
    webStripeElementCompleted = false;
    clubCurrencySub: Subscription;
    clubCurrency: string;
    stripeAccountReference: string;

    public PAYMENT_METHOD_COMPLETE = 'complete';
    public PAYMENT_METHOD_PER_PARTICIPANT = 'per_participant';
    public PAYMENT_METHOD_DEPOSIT = 'deposit';
    public PAYMENT_METHOD_ON_THE_SPOT = 'on_the_spot';

    constructor(
        private modalCtrl: ModalController,
        private route: ActivatedRoute,
        private platform: Platform,
        private loaderService: LoaderService,
        private translate: TranslateService,
        private stripeService: StripeService,
        private paymentCardService: PaymentCardService,
        private dateUtil: DateUtil,
        public sanitizer: DomSanitizer,
        private authService: AuthService,
        private clubStore: Store<ClubState>,
        private toastService: ToastService,
        private environmentService: EnvironmentService,
        private accountService: AccountService,
        private managePaymentService: ManagePaymentService,
        private bookingService: BookingService,
        private clubIdStorageService: ClubIdStorageService,
        private clubService: ClubService,
        @Inject(LOCALE_ID) public locale: string
    ) {
        this.stripeService.reloadStripeAccount();
        this.loaded = false;
        this.platform.backButton.subscribeWithPriority(101, async () => {
            this.dismiss();
        });
        this.paymentsProvider = environmentService.getEnvFile().paymentsProvider;
        this.selectedProvider = this.paymentsProvider[0];
        this.card$ = this.stripeService.card;
    }

    ngOnInit() {
        this.defaultCard = null;
        this.booking.payments = [];
//    this.booking.price = this.cart['restToPay'];
        this.booking.restToPay = this.cart['restToPay'];
        this.restToPay = this.cart['restToPay'];
        this.restToPay2 = this.restToPay;

        this.initConf();
    }

    initConf() {
        this.clubIdStorageService.getClubId().then(clubId => {
            this.guid = clubId;
            this.authService.getConnectedUser(this.guid).pipe(
                switchMap(data => {
                    this.userMe = data;
                    return of(this.guid);
                }),
                switchMap((guid: string) => this.clubService.getClub(guid)),
                switchMap(club => {
                    this.clubReal = club;
                    this.club = club;
                    return this.accountService.getClientClub(this.userMe.id, this.guid);
                }),
                tap(async (response) => {

                    this.client = response['hydra:member'][0];
                    this.wallet = this.client.wallet;

                    this.price = this.booking.timetableBlockPrice['@id'];

                    const methods = ['per_participant'];
                    this.selectedMethod = methods[0];
                    await this.clubStore.pipe(
                        select(getClubCurrency),
                        tap((currency: any) => {
                            this.clubCurrency = currency;
                        })).subscribe();

                    await this.clubStore.pipe(
                        select(getClubStripeAccountReference),
                        tap((res: any) => {
                            this.stripeAccountReference = res;
                        }),
                    ).subscribe();

                    this.clubStore.pipe(
                        select(getCurrentClub),
                        tap(club => {
                            this.club = club;
                            this.getPaymentMethod();
                            this.getPaymentCards();
                        })
                    );

                    if (this.clubReal){
                        this.stripeAccountReference = this.clubReal.stripeAccountReference;
                    }

                    this.isReady = true;
                    this.loaded = true;
                })).subscribe();
        });
    }

    ionViewWillEnter() {
        if (this.selectedProvider === 'orange_money') {
            this.checkOm();
        }
    }

    triggerPaymentData(result) {
        this.webStripeElementCompleted = result;
    }


    getPayments(event) {
        this.booking = event.booking;
        this.calculateRestToPay();
    }


    async cancelBooking() {
        this.booking = await this.bookingService.get(this.booking['@id']).toPromise();
        this.booking.payments = [];
        let userHere = await this.bookingService.getBookingParticipants(this.booking['@id'], this.userMe.id).toPromise();

        await this.bookingService.getBookingParticipants(this.booking['@id'], this.userMe.id).subscribe(
            async (response) => {
                userHere = response['hydra:member'][0];
                await this.bookingService.updateParticipantCourse({canceled: true, accompanyingParticipants: []}, userHere['@id'])
                    .subscribe(
                    async () => {
                        this.userMe = await this.authService.getConnectedUser(this.guid).toPromise();
                        this.booking = await this.bookingService.get(this.booking['@id']).toPromise();
                    }
                );
            }
        );
    }

    calculateRestToPay() {
        let amount = this.restToPay;
        switch (this.selectedMethod) {
            case 'per_participant':
                amount = amount;
                break;
        }
        if (this.booking.payments) {
            this.booking.payments.forEach(payment => {
                switch (payment.provider) {
                    case 'payment_token':
                        amount = amount - payment.amount;
                        break;
                    case 'wallet':
                        amount = amount - payment.amount;
                        break;
                }
            });
        }

        this.restToPay = amount;
        this.restToPay2 = amount;
    }


    changeMethod(event) {
        this.selectedMethod = event;
        this.booking.paymentMethod = this.selectedMethod;
        this.calculateRestToPay();
    }


    checkOm() {
    }

    changeProvider(event) {
        this.selectedProvider = event;
    }

    updateSelectedCard(event) {
        this.defaultCard = event;
        if (this.defaultCard != null) {
            this.restToPay2 = 0;
        }
        this.stripeService.setCard(this.defaultCard);
    }

    dismiss(realod?) {
        if (!this.isPaid) {
            this.cancelBooking();
        }
        this.modalCtrl.dismiss(this.isPaid);
    }

    getPaymentMethod() {
        this.paymentCardService.getAllPaymentCards()
            .then((result: any) => {
                this.paymentCard = result;
            });
    }

    getPaymentCards() {
        this.paymentCardService.getDefaultPaymentCard()
            .then(cardsString => {
                this.defaultCard = null;
                const arrayData: Array<PaymentCard> = JSON.parse(cardsString.value);
                if (arrayData) {
                    arrayData.forEach(item => {
                        if (item.default) {
                            this.defaultCard = item;
                        }
                    });
                }
            });
    }

    async confirmPayments() {
        this.loaderService.presentLoading();

        let paymentsSent = 0;
        let clientId;
        const temp = [];
        if (this.booking.payments) {
            this.booking.payments.forEach((element, index) => {
                this.booking.payments[index].client = this.client['@id'];
                if (element.payment !== undefined) {
                    if (element.payment.status !== "refunded" && element.payment.status !== "processing") {
                        temp.push(element);
                    }
                }  else {
                    temp.push(element);
                }
            });
            this.booking.payments = temp;
        }

        if (this.booking.payments.length > 0) {
            this.managePaymentService.sendPayments(this.booking.payments)
                .pipe(
                    tap(resp => {
                        paymentsSent += 1;
                    }),
                    last(),
                    tap(resp => {
                        this.calculateRestToPay();
                    }),
                    filter(payment => payment !== undefined),
                    map(payment => {
                        return payment;
                    })
                )
                .subscribe((paymentData) => {
                    if (this.restToPay > 0 && paymentData) {
                        // this.restToPay = this.booking.restToPay;
                        if (this.client['@id']) {
                            clientId = this.client['@id'];
                        }
                        this.managePaymentService.createPaymentBooking(
                            this.booking,
                            this.cart,
                            this.selectedProvider,
                            'card',
                            this.club.currency,
                            this.restToPay,
                            this.client['@id'],
                            this.booking.userClient
                        )
                            .pipe(
                                filter(payment => payment !== undefined),
                                tap(payment => {
                                    this.managePaymentResponse(payment, this.client['@id']);
                                })
                            )
                            .subscribe();

                    } else if (paymentData && this.restToPay <= 0) {
                        this.managePaymentResponse(paymentData, this.client['@id']);
                    } else if (!paymentData) {
                        this.toastService.presentError('Problème lors du paiement, réessaye !');
                    }
                });
        } else {
            if (this.restToPay > 0) {

                if (this.client['@id']) {
                    clientId = this.client['@id'];
                }
                this.managePaymentService.createPaymentBooking(
                    this.booking,
                    this.cart,
                    this.selectedProvider,
                    'card',
                    this.club.currency,
                    this.restToPay,
                    this.client['@id'],
                    this.booking.userClient
                )
                    .pipe(
                        filter(payment => payment !== undefined),
                        tap(payment => {
                            this.managePaymentResponse(payment, this.client['@id']);
                        })
                    )
                    .subscribe();
            }
        }
    }



    async managePaymentResponse(payment, clientId) {
        this.stripeAccountReference = this.clubReal.stripeAccountReference;
        switch (payment.provider) {
            case 'stripe':
                this.stripeService.clientSecret = payment.metadata.clientSecret;
                if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
                    this.stripeService.confirmWebPayment()
                        .then(res => {
                            if (!res.paymentIntent) {
                                res = JSON.parse(res);
                            }
                            if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
                                this.modalCtrl.dismiss({ booking: this.booking, success: true }).then(() => {
                                    this.toastService.presentSuccess('payment_booking_succeeded');
                                    this.loaderService.dismiss();
                                    this.modalCtrl.create({
                                        component: BookingSuccesModalComponent,
                                        componentProps: {
                                            bookingIRI: this.booking.id,
                                            msgCourse: 'course_validated'
                                        },
                                        animated: true
                                    })
                                        .then(modal => {
                                            modal.onDidDismiss()
                                                .then(data => {
                                                    this.isPaid = true;
                                                    modal.dismiss().then(_ => this.dismiss());
                                                });
                                            modal.present();
                                        });
                                    if (this.environmentService.isThisMB('padelhorizon')) {
                                        window.location.href = "https://www.padel-horizon.com/merci/";
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            this.loaderService.dismiss();
                        });
                } else {
                    this.stripeService.confirmPayment(this.defaultCard, this.stripeAccountReference, clientId)
                        .then(res => {
                            if (!res.paymentIntent) {
                                res = JSON.parse(res);
                            }
                            if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
                                this.modalCtrl.dismiss({ booking: this.booking, success: true }).then(() => {
                                    this.toastService.presentSuccess('payment_booking_succeeded');
                                    this.loaderService.dismiss();
                                    this.modalCtrl.create({
                                        component: BookingSuccesModalComponent,
                                        componentProps: {
                                            bookingIRI: this.booking.id,
                                            msgCourse: 'course_validated'
                                        },
                                        animated: true
                                    })
                                        .then(modal => {
                                            modal.onDidDismiss()
                                                .then(data => {
                                                    this.isPaid = true;
                                                    modal.dismiss().then(_ => this.dismiss());
                                                });
                                            modal.present();
                                        });
                                    if (this.environmentService.isThisMB('padelhorizon')) {
                                        window.location.href = "https://www.padel-horizon.com/merci/";
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            this.loaderService.dismiss();
                        });
                }
                break;
            case 'payment_token':
            case 'wallet':
                if (payment.status === 'succeeded') {
                    this.modalCtrl.dismiss({ booking: this.booking, success: true }).then(() => {
                    this.loaderService.dismiss();
                    this.toastService.presentSuccess('payment_booking_succeeded');
                    this.modalCtrl.create({
                        component: BookingSuccesModalComponent,
                        componentProps: {
                            bookingIRI: this.booking.id,
                            msgCourse: 'course_validated'
                        },
                        animated: true
                    })
                        .then(modal => {
                            modal.onDidDismiss()
                                .then(data => {
                                    this.isPaid = true;
                                    modal.dismiss().then(_ => this.dismiss());
                                });
                            modal.present();
                        });
                    });
                }
                break;
        }
    }
    ngOnDestroy(): void {

    }
}

import { Injectable } from '@angular/core';
import { Geolocation, GeolocationOptions, Geoposition, PositionError } from '@ionic-native/geolocation/ngx';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/app.state';
import * as GeolocationActions from '../../../state/actions/geolocation.actions';
import { AROUND_ME } from 'src/app/modal/place-search/place-search.page';
import * as ValidatedSearchActions from '../../../state/actions/validated-search.actions';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Storage } from '@capacitor/storage';

export enum GEOLOCATION_ERROR {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}

export interface GeolocationErrorMessage {
  title: string;
  text: string;
}

@Injectable()
export class GeolocationService {
  options: GeolocationOptions;
  currentPos: Geoposition;
  subscription$: Subscription;
  stopPopagation: boolean;

  constructor(
    private geolocation: Geolocation,
    private store: Store<AppState>,
    private translateService: TranslateService,
  ) {}

  destroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  getCurrentPosition(origin?, stopPropagation = false) {
    return new Promise((resolve, reject) => {
      this.subscription$ = this.store.select('geolocation').subscribe(data => {
        if (data.longitude && data.latitude) {

          this.store.dispatch(new ValidatedSearchActions.AddValidatedSearch(AROUND_ME));
          Storage.set({
            key: 'GEOLOCATION', value: JSON.stringify({
              longitude: data.longitude,
              latitude: data.latitude
            })
          })
          .then( resp => resolve(data));
        } else {
          this.options = {
            maximumAge: 3000,
            enableHighAccuracy: true
          };
          try {

            return this.geolocation.getCurrentPosition(this.options)
            .then((pos: Geoposition) => {
              this.currentPos = pos;
              Storage.set({
                key: 'GEOLOCATION', value: JSON.stringify({
                  longitude: pos.coords.longitude,
                  latitude: pos.coords.latitude
                })
              });
              this.store.dispatch(
                  new GeolocationActions.AddGeolocation({
                    longitude: pos.coords.longitude,
                    latitude: pos.coords.latitude
                  })
              );
              this.store.dispatch(new ValidatedSearchActions.AddValidatedSearch(AROUND_ME));
              /* resolve({
                longitude: pos.coords.longitude,
                latitude: pos.coords.latitude
              }); */
            })
            .catch(err => {
              Storage.get({key: 'GEOLOCATION'})
                .then( geoloc => {
                  if (geoloc.value !== undefined && geoloc.value !== null) {
                    const geolocation = JSON.parse(geoloc.value);
                    this.stopPopagation = true;
                    this.store.dispatch(
                      new GeolocationActions.AddGeolocation({
                        longitude: geolocation.longitude,
                        latitude: geolocation.latitude
                      })
                    );
                  } else {
                    reject(this.composeErrorMessage(err));
                  }
                });
            });
          } catch (t) {
            console.error('catch error : ', t);
          }
        }
      });

    });
  }

  composeErrorMessage(error): GeolocationErrorMessage {
    let message: GeolocationErrorMessage = {
      title: "",
      text: ""
    };

    switch (error.code) {
      case GEOLOCATION_ERROR.PERMISSION_DENIED:
        message = {
          title: this.translateService.instant('geolocation_deactivated_title'),
          text: this.translateService.instant('geolocation_deactivated_text')
        };
        break;
      case GEOLOCATION_ERROR.POSITION_UNAVAILABLE:
        message = {
          title: this.translateService.instant('geolocation_unavailable_title'),
          text: this.translateService.instant('geolocation_unavailable_text')
        };
        break;
      case GEOLOCATION_ERROR.TIMEOUT:
        message = {
          title: this.translateService.instant('geolocation_unavailable_timeout_title'),
          text: this.translateService.instant('geolocation_unavailable_timeout_text')
        };
        break;
      default:
        message = {
          title: this.translateService.instant('geolocation_unavailable_title'),
          text: this.translateService.instant('geolocation_unavailable_text')
        };
        break;
    }
    return message;
  }


}

export enum FilterKey {
    SURFACE = 'SURFACE',
    SORT = 'SORT',
    ACTIVITY = 'ACTIVITY',
    DISTANCE = 'DISTANCE',
    ENVIRONMENT = 'ENVIRONMENT'
}

export enum FilterCategory {
    CLUB = 'CLUB',
    MATCH = 'MATCH',
    PLAYGROUND = 'PLAYGROUND',
    ALL = 'ALL'
}

export interface Filter {
    keyFilter: FilterKey;
    value: string;
    label: string;
    category: FilterCategory;
}
const ACTIVITY = 'playground.activity.id';
const SURFACE = 'playground.surface.id';
const LOCATION = 'location';

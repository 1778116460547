import { Component, OnInit } from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {BookingService} from '../../shared/services/booking/booking.service';
import {AccountService} from '../../shared/services/account/account.service';
import {UserService} from '../../shared/services/storage/user.service';
import {ClubService} from '../../shared/services/club/club.service';
import * as moment from 'moment';
import {Club} from '../../shared/models/club';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user';

import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import {BookingGroupPaymentComponent} from "../../modal/booking-group-payment/booking-group-payment.component";
import {ListSubscriptionsClubComponent} from "../../modal/list-subscriptions-club/list-subscriptions-club.component";

@Component({
  selector: 'app-my-subscriptions',
  templateUrl: './my-subscriptions.component.html',
  styleUrls: ['./my-subscriptions.component.scss']
})
export class MySubscriptionsComponent implements OnInit {

  user: User;
  data: Array<{
    club: Club,
    subscriptionCards: Array<any>
  }>;
  selectedView: string;
  selectedViewSub$ = new BehaviorSubject<string>('active');
  selectedView$ = this.selectedViewSub$.asObservable();
  clients: Array<any>;
  pathUrl: string;
  env;
  isLoaded = false;

  constructor(
    private modalCtrl: ModalController,
    private accountService: AccountService,
    private userService: UserService,
    private clubService: ClubService,
    private environmentService: EnvironmentService
  ) {
    this.selectedView = 'active';
    this.env = environmentService.getEnvFile();
    this.pathUrl = environmentService.getEnvFile().pathFiles;
  }

  ngOnInit(): void {
    this.load();
  }

  segmentChanged(event) {
    this.selectedView = event.detail.value;
    /* TODO: implement filter active / inactive */
    if (event.detail.value === "active") {
      this.load();
    }
  }
  openSubscriptions() {
    this.modalCtrl.create({
      component: ListSubscriptionsClubComponent,
      id: 'modal-list-subscriptions',
      componentProps: {},
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios'
    })
      .then(modal => {
        modal.present().then();
        modal.onDidDismiss().then( data => {
          if (data.data) {
            this.load();
          }
        });
      });
  }
  load() {
    this.data = [];
    this.userService.get()
      .pipe(
        filter(user => user !== null),
        tap(user => this.user = user),
        switchMap( user => {
          return this.accountService.getMyClients(user.id);
        }),
        filter(data => data !== undefined),
        map( data => data['hydra:member']),
        map( data => {
          let clients = [];
          if (this.environmentService.getEnvFile().useMb) {
            this.environmentService.getEnvFile().marqueBlanche.clubIds.forEach( id => {
              if ( data.find( datum => datum.club === '/clubs/' + id) !== undefined) {
                clients.push(data.find( datum => datum.club === '/clubs/' + id));
              }
              // clients.push( data.filter( datum => datum.club === '/clubs/' + id));
            });
          } else {
            clients = data;
          }
          if (clients.length === 0) {
            this.isLoaded = true;
          }
          return clients;
        }),
        // filter( data => data.length > 0),
        tap(data => {
          if (data !== undefined) {
            data.forEach(client => {
              const dataSubCard = {
                club: null,
                subscriptionCards: []
              };
              this.clubService.get(client.club)
                .pipe(
                  filter( club => club !== undefined),
                  tap( club => dataSubCard.club = club),
                  tap( club =>  {
                    if (client.subscriptionCardsAvailable) {
                      client.subscriptionCardsAvailable.forEach(item => {
                        this.accountService.getSuscriptionCards(item['@id'])
                          .pipe(
                            filter(subCard => subCard !== undefined),
                            tap( subCard => dataSubCard.subscriptionCards.push(subCard))
                          )
                          .subscribe();
                      });
                      this.data.push(dataSubCard);
                      this.isLoaded = true;
                    }
                    this.isLoaded = true;
                  })
                )
                .subscribe();
            });
          } else {
            this.isLoaded = true;
            this.data = [];
          }
        })
      )
      .subscribe();
  }

  close() {
    this.modalCtrl.dismiss({refresh: true});
  }
}

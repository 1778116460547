import { Component, OnInit } from '@angular/core';
import {ActivityService} from '../../shared/services/activity/activity.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit {

  constructor(
      private modalCtrl: ModalController
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modalCtrl.dismiss({refresh: true}).then();
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountService } from '../../shared/services/account/account.service';
import { UserService } from '../../shared/services/storage/user.service';
import { ClubService } from '../../shared/services/club/club.service';

import {EnvironmentService} from "../../shared/services/environment/environment.service";
import {CreditDetailsComponent} from "./credit-details/credit-details.component";

@Component({
  selector: 'app-my-credits',
  templateUrl: './my-credits.component.html',
  styleUrls: ['./my-credits.component.scss']
})
export class MyCreditsComponent implements OnInit {
  env;
  isLoaded = false;

  credits: Array<any>;
  constructor(
    private modalCtrl: ModalController,
    private accountService: AccountService,
    private userService: UserService,
    private clubService: ClubService,
    private environmentService: EnvironmentService
  ) {
    this.env = this.environmentService.getEnvFile();
    this.credits = [];
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.userService.getStoredClients()
      .then(clients => {
        if (clients !== null && clients !== undefined) {
          clients.forEach(client => {
            if (client) {
              const creditData = {
                club: null,
                credits: []
              };
              this.clubService.get(client.club)
                .subscribe(club => {
                  creditData.club = club;
                });
              this.accountService.getClientCredits(client.client.replace('/clubs/clients/', ''))
                .subscribe(data => {
                  if (data) {
                    creditData.credits = data['hydra:member'];
                    this.credits.push(creditData);
                    this.isLoaded = true;
                  }
                });
            }
          });
        } else {
          this.isLoaded = true;
          this.credits = [];
        }
      });
  }

  seeCreditDetails(credit: any) {
    this.modalCtrl.create({
      component: CreditDetailsComponent,
      componentProps: {
        creditSelected: credit
      }
    }).then(modal => {
        modal.present().then();
        modal.onDidDismiss().then( data => {
        })
      })
  }

  close() {
    this.modalCtrl.dismiss({refresh: true});
  }
}

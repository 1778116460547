import { HeaderActionsComponent } from './header-actions/header-actions.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import { FullDateComponent } from './full-date/full-date.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import {PipesModule} from "../../shared/pipes/pipes.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        IonicModule,
        PipesModule,
    ],
  declarations: [
    HeaderActionsComponent,
    FullDateComponent,
    StripePaymentComponent
  ],
  exports: [
    HeaderActionsComponent,
    FullDateComponent,
    StripePaymentComponent
  ],
})
export class SharedComponentsModule {}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PlaygroundOption} from "../../shared/models/playgroung-option";
import {IonRadioGroup} from "@ionic/angular";

@Component({
  selector: 'app-manage-participants-group-booking',
  templateUrl: './manage-participants-group-booking.component.html',
  styleUrls: ['./manage-participants-group-booking.component.scss']
})
export class ManageParticipantsGroupBookingComponent implements OnInit {

  @ViewChild('radioGroup') radioGroup: IonRadioGroup;

  @Input() categories;
  @Input() countParticipant;
  @Input() participantsList: Array<any>;
  @Output() manageUserPresence = new EventEmitter<{ isUserPresent: boolean, catToReplace: string }>();
  userToReplace: any = null;

  constructor() { }

  ngOnInit(): void {
    /*this.countParticipant.forEach((key, value) => {
      if (key > 0) {
        const cat = this.categories.find(c => c.category['@id'] === value);
        this.participantsList.push({ label: cat.category.label, category: cat.category['@id'] });
      }
    });*/
  }

  onChange(value) {
    this.radioGroup.value = value;
    this.participantsList.forEach(part => {
      if (part.label === value) {
        this.userToReplace = part;
        this.manageUserPresence.emit({isUserPresent: true, catToReplace: this.userToReplace.category});
      }
    });
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../state/app.state";
import {ModalController} from "@ionic/angular";
import * as moment from 'moment';

@Component({
  selector: 'app-choices-duration',
  templateUrl: './choices-duration.component.html',
  styleUrls: ['./choices-duration.component.scss']
})
export class ChoicesDurationComponent implements OnInit {

  @Input() slot;
  @Input() startAt;
  @Input() playgroundName;
  @Input() bookingsOrder;
  @Input() club;
  currency;

  constructor(
      private store: Store<AppState>,
      private modalController: ModalController
  ) { }

  ngOnInit(): void {

  }
  availablePrices() {
    const prices = [];
    this.slot.prices.forEach(item => {

      const start = moment(this.startAt.format('YYYY-MM-DD HH:mm'));
      const end = moment(this.startAt.format('YYYY-MM-DD HH:mm'));
      end.add(item.duration, 'seconds');
      let validation = true;
      this.bookingsOrder.forEach(booking => {
        if (booking.start < end.unix() && booking.end >= end.unix()) {
          validation = false;
        }
      });

      if (validation) {
        prices.push(item);
      }
    });

    return prices.reverse();
  }
  selectDuration(duration) {
    this.modalController.dismiss({duration, success: true}).then();
  }

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import {AccountService} from "../../../shared/services/account/account.service";
import {map, tap} from "rxjs/operators";
import {EnvironmentService} from "../../../shared/services/environment/environment.service";
import {Brightness} from "@ionic-native/brightness/ngx";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-my-qr-code',
  templateUrl: './my-qr-code.component.html',
  styleUrls: ['./my-qr-code.component.scss']
})
export class MyQrCodeComponent implements OnInit, OnDestroy {

  @Input() userMe: any;
  @Input() clubId: any;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = null;
  env: any;
  currentBrightness: any

  constructor(private accountService: AccountService,
              private environmentService: EnvironmentService,
              private brightness: Brightness,
              private modalController: ModalController) {
    this.env = this.environmentService.getEnvFile();
  }


  ngOnInit(): void {
    this.brightness.getBrightness().then(brightness => {
      this.currentBrightness = brightness;
      this.brightness.setBrightness(1);
    });
    if (this.userMe && this.clubId) {
      this.accountService.getClientClub(this.userMe.id, this.clubId).pipe(
          tap(clientClub => {
            this.value = clientClub['hydra:member'][0].accessCode;
          })
      ).subscribe();
    }
  }

  close() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.brightness.setBrightness(this.currentBrightness);
  }
}
